import React, { useContext, useEffect, useState } from "react";
// import AddUserPopUP from "../../../Components/AddUserSport";
// import AuthContext from "../../../Context/AuthProvider";
import { AuthContext } from "../Context/AuthProvider";
import AddUser from "../components/AddUser";
import "../style/UserManagement.css";

// import AuthContext from '../../Context/AuthProvider';
function UserMgmt() {
  const {usersMgmt,getUserManagementData,loogedinAdmin,Url,addUserPopUp, setAddUserPopUp,getAllBookingsData} = useContext(AuthContext)
  const [popup, setPopup] = useState();
  const [userData, setuserData,] = useState();
  const [isEditpPop, setIsEditPop,] = useState();
  const [fetchUserManagementData,] = useState();
  const [setUserEditData,] = useState();
  const [Users, setUsers,] = useState([]);
  const [EngineNewUrl,] = useState();
  const [isAdmin] = useState();
  const [EngineUrl] = useState();
  const [userAccess] = useState();

  
  const deleteUserFromManagement = async (emailId) => {
    try {
      const response1 = await fetch(
        `${Url}/sports/deleteuser/${localStorage.getItem("adminToken")}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: emailId }), // Serialize the data as JSON
        }
      );
      const json1 = await response1.json();

      // alert(json1.Message)
      getUserManagementData();
    } catch (error) {
      alert("User Management API error");
    }
  };

  useEffect(()=>{
    getUserManagementData()
  },[])

  const AddUserHandlePopUp = () => {
    setAddUserPopUp(true);
  };

  const editUserData = (user) => {
    setIsEditPop(true);
    setUserEditData(user);
    // setIsEdit(true)
    // console.log(user)
  };


  
  return (

    <div className="lg:max-w-[1280px] mx-auto mt-5 mb-5 relative">
      <div className="flex justify-end mb-4 max-md:px-5 ">
        {/* {userAccess.isAdmin ? <button className="btn " onClick={handlePopUp}> */}
        {loogedinAdmin?<button className="button" onClick={AddUserHandlePopUp}>
          + Add User
        </button>:""}
      </div>
      <div className="usermanagement">
        <table className="usermanagementtable mt-4">
          <thead>
            <tr className="tablerow">
              {["Name", "Email", "Admin", "Action"].map((headerLabel) => (
                <th key={headerLabel}>{headerLabel}</th>
              ))}
            </tr>
          </thead>

        <tbody>
          {usersMgmt.map((user) => (
            <tr>
              <td>{user.UserName}</td>
              <td>{user.Email}</td>
              {/* <td className='d-flex flex-column'>
                {Object.entries(user.accessScope).map(([key, value]) => (
                  value && <span className='badge bg-success m-1 w-auto' key={key}>{key}</span>
                ))}
              </td> */}
              <td>{user.isAdmin ? "Yes" : "No"}</td>
              <td>
                {loogedinAdmin && !user.isAdmin ?<button className='btn ' style={{width:"60%"}} onClick={() => { deleteUserFromManagement(user.Email) }}>Delete</button> : ""}
                {/* {userAccess.isAdmin ? user.isAdmin ? (
                  ""
                ) : (
                  <button
                    className="btn"
                    onClick={() => {
                      deleteUserFromManagement(user.Email);
                    }}
                  >
                    delete
                  </button>
                ) : ""} */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {addUserPopUp ? <AddUser /> : ""}

      {/* {popup ? (
        <AddUserPopUP setPopup={setPopup} GetAllUsers={GetAllUsers} />
      ) : (
        ""
      )} */}
      {/* {isEditpPop ? <EditUserMagPopup /> : ""} */}
    </div>
  )
}

export default UserMgmt
