import React, { useContext, useState } from 'react'
import { AuthContext } from '../Context/AuthProvider'
import EditSports from "./EditContact"

function Sports() {
  const { Url, Sports } = useContext(AuthContext)
  const [isEdit, setisEdit] = useState(false)
  const [editObject, seteditObject] = useState("None")
  const [editsportid, seteditsportid] = useState("None")

  const TogglePopup = (data) => {
    seteditObject(data)
    seteditsportid(data.sportId)
    setisEdit(true)
  }

  return (
    <div className='lg:max-w-[1280px] max-w-full mx-auto mt-5 mb-5'>
      {isEdit && editObject !== "None" ? <EditSports setPopup={setisEdit} data={editObject} setdata={seteditObject} editsportid={editsportid} /> :
      <div className='grid grid-cols-4 gap-5 max-md:grid-cols-1 px-5' >
        {Sports.map((sport) => {
          return <div class="card rounded-md overflow-hidden"  >
            <img src={sport["images"][0]} style={{ height: "80%", width: "100%", objectFit: "cover" }} class="card-img-top" alt="..." />
            <div class="card-body flex justify-between items-center mt-5">
              <h5 class="card-title">{sport.sportName}</h5>
              {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
              <a class="btn py-[.3rem] px-[1rem]" onClick={() => { TogglePopup(sport) }} style={{ "cursor": "pointer" }}>Edit</a>
            </div>
          </div>

        })}
      </div>}
    </div>
  )
}

export default Sports