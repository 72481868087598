import React from 'react';
import { Helmet } from "react-helmet";
import ContactBanner from "../assets/compressed/DSC05473_5_11zon.jpg";
import ComBanner from '../components/ComBanner';
import Form from '../components/Form';

const Contact = () => {



    const contact = [ContactBanner]
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact for Sports Partnerships & Inquiries</title>
                <meta name="description" content="Connect with LoneStar Kashmir FC for inquiries, collaborations, and partnerships."/>
            </Helmet>
            <ComBanner
                bannerImage={contact}
                heading={"Contact Us"}
                subHeading={""}
                button={true}

            />

            <Form />
        </div>
    )
}

export default Contact