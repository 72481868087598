import React, { useContext, useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import { AuthContext } from '../Context/AuthProvider';

const AddUser = () => {
    const {Url,getUserManagementData} = useContext(AuthContext);
    const [number, setNumber] = useState("");
    const [name, setName] = useState("");
    const [Password, setPassword] = useState("Eazotel@123");
    const [Email, setEmail] = useState("");
    const { addUserPopUp, setAddUserPopUp } = useContext(AuthContext);



    const submitForm = () => {
        setAddUserPopUp(false);
    }
    const handleCloseClick = () => {
        setAddUserPopUp(false);
    }

    const AddUserFromManagement = async () => {
        if(number==="" || name==="" || Password==="" || Email==="" ){
            alert("Please Complete Details")
        }
        else{
            try {
                const response1 = await fetch(
                  `${Url}/sports/addnewuser/${localStorage.getItem("adminToken")}`,
                  {
                    method: "POST",
                    headers: {
                      Accept: "application/json, text/plain, */*",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      "username":name,
                      "email":Email,
                      "password":Password,
                      "phone":number
                  }), // Serialize the data as JSON
                  }
                );
                const json1 = await response1.json();
                  
                if(json1.Status){
                    getUserManagementData();
                    handleCloseClick()
                }
                else{
                    alert(json1.Message)
                }
                
              } catch (error) {
                alert("User Management API error");
              }
        }
      };




    return (
        <div className='fixed top-0 left-0 z-10 w-full max-md:px-5 h-[100%] flex justify-center items-center bg-[#000000A6]'>
            <div className=" fixed flex flex-col items-center py-3 px-3 bg-white rounded-2xl max-w-[586px] lg:w-[400px]">
                <div className='flex w-full justify-end'>
                    <RxCross2 style={{ cursor: "pointer" }} size={24} onClick={handleCloseClick} color='#333333' />
                </div>
                <div className=' flex flex-col px-5 max-md:px-5 w-full'>
                    <div className="text-[20px] font-[500] !bg-transparent !text-[#333333]">
                        Full Name
                    </div>
                    <input
                        type='text'
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                        placeholder='Name'
                        className="justify-center self-stretch px-4 py-1 rounded-[8px]  border-brown max-md:max-w-full"
                    />
                    <div className="text-[20px] font-[500] !bg-transparent mt-2 !text-[#333333]">
                        Mobile number
                    </div>
                    <input
                        type='number'
                        value={number}
                        onChange={(e) => { setNumber(e.target.value) }}
                        placeholder='Mobile Number'
                        // readOnly={sendotp ? true : false}
                        className="justify-center self-stretch px-4 py-1 rounded-[8px] border-brown max-md:max-w-full "
                    />
                    <div className="text-[20px] font-[500] !bg-transparent !text-[#333333] mt-2">
                        Email ID
                    </div>
                    <input
                        type='email'
                        value={Email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        placeholder='abc@gmail.com'
                        className="justify-center self-stretch px-4 py-1 rounded-[8px] border-brown max-md:max-w-full"
                    />
                    

                    <div className='flex justify-center'>
                        <button onClick={() => { AddUserFromManagement() }} type='submit' className="flex justify-center items-center font-semibold !text-[16px] py-[12px] px-[36px] btn rounded-[44px] mt-4" style={{ backgroundColor: "#AF5800", color: "white" }} >
                            Add User
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUser