import React, { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { IoMdAdd } from "react-icons/io";
import { RiSubtractFill } from "react-icons/ri";
import useRazorpay from "react-razorpay";
import { Link } from 'react-router-dom';
import { AuthContext } from '../Context/AuthProvider';
import Banner from '../components/Banner';
import Popup from '../components/popup';
import Success from './Success';





const Home = () => {

    const {
        date, setDate, isAuth, Url,
        sportsData, setSportsData,
        selectedSportId, setSelectedSportId,
        selectedSlottId, setSelectedSlotId,
        price, setPrice,
        turfName, setTurfName,
        counter, setCounter,
        selectedSlotName, setselectedSlotName,
        selectedSlotStart, setselectedSlotStart,
        selectedSlotEnd, setselectedSlotEnd,
        AuthFlowOtp, setAuthFlowOtp, Profile, selectedSportName, setselectedSportName, SlotsCounterData,
        setSlotsCounterData, subtotalDict, setsubtotalDict
    } = useContext(AuthContext);

    const minDate = new Date();
    const maxDate = new Date(minDate.getFullYear(), 11, 31);

    const [TotalPrice, setTotalPrice] = useState(0)
    const [OrderId, setOrderId] = useState("None")
    const [Razorpay, createOrder] = useRazorpay();
    const [bookingDone, setbookingDone] = useState(false)
    const [successBookingDetails, setsuccessBookingDetails] = useState("None");
    const [availableSlot, setAvailableSlot] = useState(null);
    const [maxValueCounter, setmaxValueCounter] = useState(1)
    const [openInitialPopup, setopenInitialPopup] = useState(false)
    const [orderProcessing, setorderProcessing] = useState(false)

    function getMaxValue(obj) {
        const values = Object.values(obj);
        let maxv = Math.min(...values);
        if (maxv === 0) {
            return 1
        }
        return Math.min(...values);
    }

    function findPriceOnturfandSlot(slotname, turfname) {
        if (selectedSportId === "RV7p97oHCriyEY6pLxwHEz") {
            for (let i = 0; i < sportsData[0].slotData.length; i++) {
                if (sportsData[0].slotData[i].slotName === slotname) {
                    for (let j = 0; j < sportsData[1].slotData[i].Turfs.length; j++) {
                        if (sportsData[0].slotData[i].Turfs[j].name === turfname) {
                            return sportsData[0].slotData[i].Turfs[j].price
                        }
                    }
                }
            }
        }
        else {
            for (let i = 0; i < sportsData[1].slotData.length; i++) {
                if (sportsData[1].slotData[i].slotName === slotname) {
                    for (let j = 0; j < sportsData[1].slotData[i].Turfs.length; j++) {

                        if (sportsData[1].slotData[i].Turfs[j].name === turfname) {
                            return sportsData[1].slotData[i].Turfs[j].price
                        }
                    }
                }
            }
        }
        // //console.log(sportsData)
        return 0

    }

    const getCounterMaxProbablity = async (sportId, date) => {
        try {
            const response = await fetch(`${Url}/sports/countervalue`, {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, /",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "seid": "779f43ea-5ed0-43ee-86fb-c7d296a769cf",
                    "sportId": sportId,
                    "date": date
                }),
            });

            const json = await response.json();
            //console.log(json)
            if (json.Status === true) {
                setSlotsCounterData(json.Data)

            } else {
                //console.log("Problem")
            }
        }
        catch {
            //console.log("Problem")
        }
    };

    const handleSportClick = (sportId, sportname) => {
        setSelectedSportId(sportId);
        setselectedSportName(sportname)
        setselectedSlotName(null)
        setselectedSlotStart()
        setselectedSlotEnd()
        setSelectedSlotId(null)
        getCounterMaxProbablity(sportId, date)
    };

    const handleSlotClick = (name, start, end) => {
        setselectedSlotName(name)
        setselectedSlotStart(start)
        setselectedSlotEnd(end)
        setSelectedSlotId(`${start}-${end}`);
        setmaxValueCounter(getMaxValue(SlotsCounterData[name]))
        setPrice(0)
        setTurfName(null)
        setPrice(0)
        if (counter > 1) {
            setCounter(1)
        }


    }
    const handleTurfClick = (trufPrice, turfname) => {
        let dictofslot = {}

        let integerPart = selectedSlotName.match(/\d+/)[0];
        if (counter > 1) {
            for (let i = 0; i < counter; i++) {
                let num = Number(integerPart) + Number(i)
                dictofslot[selectedSlotName.slice(0, 4) + num] = findPriceOnturfandSlot(selectedSlotName.slice(0, 4) + num, turfname);
            }
        }
        else {
            dictofslot[selectedSlotName] = findPriceOnturfandSlot(selectedSlotName, turfname)
        }
        //console.log(dictofslot)
        setsubtotalDict(dictofslot)
        setPrice(trufPrice);
        setTurfName(turfname);
        setmaxValueCounter(SlotsCounterData[selectedSlotName][turfname])
    }

    const handleCalendarChange = (newValue) => {

        const currentDate = newValue.toLocaleDateString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/\//g, '-');
        setDate(currentDate);
        getCounterMaxProbablity(selectedSportId, currentDate)
    }

    const handleChangeButton = () => {
        setAuthFlowOtp(true)
    }

    const handleopenInitialPopup = () => {
        setopenInitialPopup(true)
    }

    const increaseCounter = () => {
        if (counter >= maxValueCounter) {
            return;
        }
        // const number = parseInt(selectedSlotName.replace(/\D/g, ''), 10); // Extracting numbers using regex
        // const nextNumber = number + 1; // Getting the next number
        // alert("Next slot: slot" + nextNumber);
        let cou = counter + 1
        setCounter(counter + 1);

        let dictofslot = {}

        let integerPart = selectedSlotName.match(/\d+/)[0];
        if (cou > 1) {
            for (let i = 0; i < cou; i++) {
                let num = Number(integerPart) + Number(i)
                dictofslot[selectedSlotName.slice(0, 4) + num] = findPriceOnturfandSlot(selectedSlotName.slice(0, 4) + num, turfName);
            }
        }
        else {
            dictofslot[selectedSlotName] = findPriceOnturfandSlot(selectedSlotName, turfName)
        }
        //console.log(dictofslot)
        setsubtotalDict(dictofslot)
    }
    const decreaseCounter = () => {
        if (counter === 1) {
            return;
        }
        let cou = counter - 1
        setCounter(counter - 1)

        let dictofslot = {}

        let integerPart = selectedSlotName.match(/\d+/)[0];
        if (cou > 1) {
            for (let i = 0; i < cou; i++) {
                let num = Number(integerPart) + Number(i)
                dictofslot[selectedSlotName.slice(0, 4) + num] = findPriceOnturfandSlot(selectedSlotName.slice(0, 4) + num, turfName);
            }
        }
        else {
            dictofslot[selectedSlotName] = findPriceOnturfandSlot(selectedSlotName, turfName)
        }
        //console.log(dictofslot)
        setsubtotalDict(dictofslot)

    }

    const GetFullPaymentForReservation = async () => {
        setTotalPrice(Number(price) + (Number(Profile.convenienceFee) / 100) * price)
        const response = await fetch(`${Url}/sports/createBooking`, {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, /",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "bookingDate": date,
                "sportId": selectedSportId,
                "seid": "779f43ea-5ed0-43ee-86fb-c7d296a769cf",
                "bookedSlots": [
                    {
                        "slotName": selectedSlotName,
                        "slotstart": selectedSlotStart,
                        "slotEnd": selectedSlotEnd,
                        "turfName": turfName,
                        "turfPrice": price
                    }
                ],
                "duration": String(counter),
                "price": {
                    "amount": Number(price),
                    "tax": (Number(Profile.convenienceFee) / 100) * price,
                    "totalAmount": Number(price) + (Number(Profile.convenienceFee) / 100) * price,
                    "amountPaid": Number(price) + (Number(Profile.convenienceFee) / 100) * price
                },
                "Token": localStorage.getItem("userAuth")
            }),
        });

        const json = await response.json();

        if (json.Status === true) {
            setOrderId(json.orderId);
            handlePayment(json.orderId)
        } else {
            document.getElementById("No_rooms").style.display = "block";
        }
    }

    const GetHalfPaymentForReservation = async () => {
        setTotalPrice(500)
        const response = await fetch(`${Url}/sports/createBooking`, {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, /",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "bookingDate": date,
                "sportId": selectedSportId,
                "seid": "779f43ea-5ed0-43ee-86fb-c7d296a769cf",
                "bookedSlots": [
                    {
                        "slotName": selectedSlotName,
                        "slotstart": selectedSlotStart,
                        "slotEnd": selectedSlotEnd,
                        "turfName": turfName,
                        "turfPrice": price
                    }
                ],
                "duration": String(counter),
                "price": {
                    "amount": price,
                    "tax": (Number(Profile.convenienceFee) / 100) * price,
                    "totalAmount": Number(price) + (Number(Profile.convenienceFee) / 100) * price,
                    "amountPaid": 500
                },
                "Token": localStorage.getItem("userAuth")
            }),
        });

        const json = await response.json();

        if (json.Status === true) {
            setOrderId(json.orderId);
            handlePayment(json.orderId)
        } else {
            document.getElementById("No_rooms").style.display = "block";
        }
    }

    const updatePaymentForReservation = async (sportId, orderid, paymentid) => {
        setorderProcessing(true)
        try {
            const response = await fetch(`${Url}/sports/updateBooking/razorpay`, {
                method: "PUT",
                headers: {
                    Accept: "application/json, text/plain, /",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "sportId": sportId,
                    "orderId": orderid,
                    "payid": paymentid

                }),
            });

            const json = await response.json();

            if (json.Status === true) {
                setsuccessBookingDetails(json.details)
                setbookingDone(true)
                setorderProcessing(false)

            } else {
                alert("ok")
                setorderProcessing(false)
            }
        }
        catch {
            //console.log("Error")
            setorderProcessing(false)
        }
    }

    const handlePayment = async (orderid) => {
        try {
            // alert(props.GatewayConnected)
            const mockOrderData = {
                amount: parseInt(TotalPrice) * 100, // Convert amount to paise (assuming INR)
                orderId: orderid, // Generate a unique order ID
            };

            const options = {
                key: Profile.gateWay.API_KEY, // Enter the Key ID generated from the Dashboard rzp_test_UZ0V9jh3jMC0C9,rzp_live_5uaIIwZcxLC70j
                amount: mockOrderData.amount.toString(), // Use the amount from the order data
                currency: "INR",
                name: "Lone Star",
                description: "Test Transaction",
                image: "https://upload.wikimedia.org/wikipedia/en/thumb/2/2e/Lonestar_Kashmir_FC.svg/800px-Lonestar_Kashmir_FC.svg.png",
                order_id: orderid, // Use the order ID from the order data
                handler: async function (response) {
                    // Alert the payment ID upon successful payment
                    if (response.razorpay_payment_id) {
                        updatePaymentForReservation(selectedSportId, orderid, response.razorpay_payment_id)

                    } else {
                        alert("Payment was unsuccessful.");
                    }
                },

                theme: {
                    color: "#FFFF00",
                },
            };

            const rzp1 = new Razorpay(options);

            rzp1.on("payment.failed", function (response) {
                alert(response.error.code);
                alert(response.error.description);
                alert(response.error.source);
                alert(response.error.step);
                alert(response.error.reason);
                alert(response.error.metadata.order_id);
                alert(response.error.metadata.payment_id);
            });

            rzp1.open();
        } catch (error) {
            //console.log("Payment Error:", error);
        }
    };

    const calculateAvailableSlot = (slot) => {
        //console.log()
    }

    function formatTime(timeString) {
        const [hours, minutes] = timeString.split(':');
        let formattedHours = parseInt(hours, 10);
        const ampm = formattedHours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        formattedHours = formattedHours % 12 || 12;

        return `${formattedHours}:${minutes} ${ampm}`;
    }
    useEffect(() => {
        setAvailableSlot(sportsData[0]?.slotData?.length);
    }, [])

    const findPriceFromDict = () => {
        let totalPrice = 0;

        // Iterate over the object properties
        for (const slot in subtotalDict) {
            // Convert the value to a number and add it to the total price
            totalPrice += parseInt(subtotalDict[slot]);
        }

        setPrice(totalPrice)
        return totalPrice
    }





    return (
        <div className='mt-5 max-w-[1280px] mx-auto'>
            <Banner />

            {bookingDone === false ? <div className="flex flex-col mx-auto w-full">


                {/* Sport Name */}


                <div className='lg:max-w-[1024px]  mx-auto grid grid-cols-2 max-md:grid-cols-1 gap-10 lg:gap-5'>

                    <div>
                        <div className="heading mt-10 max-md:px-5">
                            Select Sport
                        </div>
                        <div className="grid grid-cols-2 gap-5 max-md:grid-cols-2  max-md:px-5 mt-5 max-md:mt-10 lg:h-[45%] max-md:h-100">

                            {sportsData.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative  flex flex-1 justify-center items-center rounded-3xl w-25 max-md:w-full overflow-hidden cursor-pointer ${selectedSportId === item.sportId ? 'sportborder' : 'sportborderBefore'
                                        }`}
                                    onClick={() => handleSportClick(item.sportId, item.sportName)}
                                >
                                    <img
                                        loading="lazy"
                                        src={item.images[0]}
                                        alt={item.sportName}
                                        className="object-cover h-full w-full  inset-0 size-full overflow-hidden"
                                    />
                                    <div className="absolute inset-0 bg-black opacity-50"></div>
                                    <p className=' !text-[16px] lg:!text-[18px] font-semibold !text-white absolute bottom-[15%] max-md:bottom-[10%] left-1/2 transform -translate-x-1/2 ' >
                                        {item.sportName}
                                    </p>


                                </div>
                            )).reverse()}

                        </div>


                    </div>

                    {/* Calendar */}

                    <div className='w-full'>
                        <div className="heading  max-md:px-5 mt-10 max-md:mt-0">
                            Select Playing Date
                        </div>
                        <div className='mt-5 max-md:mt-10 flex justify-center  max-md:px-5'>
                            <Calendar
                                className="!w-[100%] custom-calendar"
                                onChange={handleCalendarChange}
                                value={date}
                                width="100%"
                                minDate={minDate}
                                maxDate={maxDate}
                            />

                        </div>
                    </div>
                </div>



                <div className='grid grid-cols-2 max-md:grid-cols-1 gap-5 max-w-[1024px] lg:w-[1024px]  max-md:w-full lg:mx-auto'>
                    <div className=' flex flex-col '>
                        <div className="w-full  max-md:px-5 lg:mx-auto heading mt-10 max-md:mt-10">
                            Select Slot
                        </div>

                        <div className='w-full max-md:mt-10  max-md:px-5  lg:mx-auto'>
                            {selectedSportId === null ?
                                <div className='flex justify-center mt-5'>
                                    <div className='text'>Please Select Any Sport</div>
                                </div>
                                :
                                <div className='grid grid-cols-2 gap-5 mt-10 max-md:mt-0'>

                                    {sportsData.map((item, index) => (
                                        item?.sportId === selectedSportId && item.slotData ? (
                                            item.slotData
                                                // Sort slotData array based on slot start time
                                                .sort((a, b) => {
                                                    // Assuming slotStart is a string representing time in HH:MM format
                                                    const timeA = a.slotStart.split(':').map(Number);
                                                    const timeB = b.slotStart.split(':').map(Number);

                                                    if (timeA[0] !== timeB[0]) {
                                                        return timeA[0] - timeB[0]; // Sort by hour
                                                    } else {
                                                        return timeA[1] - timeB[1]; // Sort by minute if hours are equal
                                                    }
                                                })
                                                .map((slot, slotIndex) => (
                                                    slot.Turfs.length === 0 ? <div key={`${index}-${slotIndex}`} style={{ cursor: "not-allowed", opacity: "0.5", backgroundColor: "red", color: "white" }} className={` flex justify-center text background whitespace-nowrap rounded-[4px]`} >
                                                        <div className="flex justify-center max-md:text-[14px] text-[18px] px-[20px] py-[16px] rounded">
                                                            {formatTime(slot.slotStart)} - {formatTime(slot.slotEnd)}
                                                        </div>
                                                    </div> :
                                                        <div key={`${index}-${slotIndex}`} style={{ cursor: "pointer" }} className={`flex justify-center text background whitespace-nowrap rounded-[4px] ${selectedSlottId === `${slot.slotStart}-${slot.slotEnd}` ? 'border' : 'borderBefore'}`} onClick={() => handleSlotClick(slot.slotName, slot.slotStart, slot.slotEnd)}>
                                                            <div className="flex justify-center max-md:text-[14px] px-[20px] py-[16px] rounded">
                                                                {formatTime(slot.slotStart)} - {formatTime(slot.slotEnd)}
                                                            </div>
                                                        </div>
                                                ))
                                        ) : null
                                    ))}
                                </div>

                            }
                        </div>
                    </div>
                    <div className=' '>
                        {/* Counter */}

                        <div className='w-full gap-5' >


                            <div>
                                <div className="heading mt-10  max-md:px-5">
                                    Select Duration
                                </div>
                                <div className="flex gap-5 justify-center  max-md:px-5 mt-10 max-md:mt-10">
                                    <button onClick={decreaseCounter} className="flex justify-center items-center px-2 w-10 h-10 background rounded-[100px]">
                                        <RiSubtractFill size={25} className='icon' />
                                    </button>
                                    <div className="my-auto text-[20px] font-bold leading-5 text-black">
                                        {counter} Hour(s)
                                    </div>
                                    <button onClick={increaseCounter} className="flex justify-center items-center px-2 w-10 h-10 background rounded-[100px]">
                                        <IoMdAdd size={25} className='icon' />
                                    </button>
                                </div>
                                {/* </div>

<div> */}
                                <div className="  heading lg:mt-7 mt-10  max-md:px-5">
                                    Select Turf
                                </div>
                                <div className="max-md:px-5 mt-10 lg:mt-[24px]">
                                    {sportsData.map((item, index) => (
                                        item?.sportId === selectedSportId && item.slotData ? (
                                            item.slotData.map((slot, slotIndex) => {
                                                // Set a value here
                                                // const availableSlot = calculateAvailableSlot(slot);

                                                // Perform action for each element in the array
                                                if (selectedSportId === "Z9rTeMFLF7xLLXKBANqz88") {

                                                    return <div key={`${index}-${slotIndex}`} className="grid grid-cols-2 gap-5">
                                                        {selectedSlottId === `${slot.slotStart}-${slot.slotEnd}` && slot.Turfs && slot.Turfs.map((turf, turfIndex) => (
                                                            turf.name === "7 v 7 Turf C" ? (
                                                                <div key={`${index}-${slotIndex}-${turfIndex}`} className={`flex justify-center text background rounded whitespace-nowrap ${turfName === turf.name ? 'border' : 'borderBefore'}`} onClick={() => handleTurfClick(turf.price, turf.name)} style={{ cursor: "pointer" }}>
                                                                    <div className="flex justify-center max-md:text-[14px]  px-[20px] py-[16px] rounded">
                                                                        {turf.name}
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                } else {

                                                    return <div key={`${index}-${slotIndex}`} className="grid grid-cols-2 gap-5">
                                                        {selectedSlottId === `${slot.slotStart}-${slot.slotEnd}` && slot.Turfs && slot.Turfs.map((turf, turfIndex) => (
                                                            turf.name === "7 v 7 Turf C" ? (
                                                                <div key={`${index}-${slotIndex}-${turfIndex}`} className={`flex justify-center text background rounded whitespace-nowrap ${turfName === turf.name ? 'border' : 'borderBefore'}`} onClick={() => handleTurfClick(turf.price, turf.name)} style={{ cursor: "pointer" }}>
                                                                    <div className="flex justify-center max-md:text-[14px]  px-[20px] py-[16px] rounded">
                                                                        {turf.name}
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                }
                                            })
                                        ) : null
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Turf */}
                    </div>
                </div>
                {/* Slot */}

                <div className='max-w-[1024px] lg:w-[1024px] max-md:w-full max-md:px-5  lg:mx-auto'>
                    {price === 0 ? "" : isAuth ?

                        <div className="flex flex-col justify-center items-center mt-10 max-md:gap-5 gap-10 w-full">
                            <div className='w-full mx-auto max-w-[651px]'>
                                <div className="flex flex-col justify-center p-8 text-2xl leading-7 rounded-3xl border-4 border-solid bg-neutral-50 border-neutral-200 max-w-[651px] text-neutral-600 max-md:px-5">
                                    <div className="flex gap-3 justify-between max-md:flex-wrap">
                                        <div className="text !text-[16px] max-md:max-w-full">Booking Fee</div>
                                        <div className="text !text-[16px]">₹ {findPriceFromDict()} </div>
                                        {/* <div className="text !text-[16px]">₹ {findPriceFromDict() + (Number(Profile.convenienceFee) / 100) * price} </div> */}
                                    </div>
                                    <div className="flex gap-3 justify-between mt-5 max-md:flex-wrap">
                                        <div className="text !text-[16px] max-md:max-w-full">Convenience Fee</div>
                                        <div className="text !text-[16px]">₹ 50</div>
                                        {/* <div className="text !text-[16px]">₹ {(Number(Profile.convenienceFee) / 100) * price}</div> */}
                                    </div>
                                    <div className="shrink-0 mt-5 h-0.5 bg-neutral-200 max-md:max-w-full" />
                                    <div className="flex gap-3 justify-between mt-5 font-bold text-zinc-800 max-md:flex-wrap">
                                        <div className="heading !text-[#333333] !p-0 !pt-[10px] !bg-transparent max-md:max-w-full">Total Fee</div>
                                        {/* <div className='heading !text-[#333333] !p-0 !pt-[10px] !bg-transparent'>₹ {Number(price) + (Number(Profile.convenienceFee) / 100) * price}</div> */}
                                        <div className='heading !text-[#333333] !p-0 !pt-[10px] !bg-transparent'>₹ {findPriceFromDict()}</div>
                                    </div>
                                </div>
                            </div>
                            {orderProcessing === false ? <div className='flex flex-col gap-2 lg:max-w-[400px] w-[400px]  max-md:w-full bg-white'>
                                <button className="flex justify-center !font-semibold items-center text w-full py-4 btn rounded-[44px]" onClick={() => { GetFullPaymentForReservation() }}>
                                    Pay Now
                                </button>
                                {/* <button className="flex justify-center !font-semibold items-center text w-full py-4 btn rounded-[44px]" onClick={() => { GetHalfPaymentForReservation() }}>
                                    Pay Partial (₹ 500)
                                </button> */}
                            </div> : <h1>Generating Invoice...</h1>}
                            {/*   {orderProcessing===false?<div className='flex flex-col gap-2 lg:max-w-[400px] w-[400px]  max-md:w-full bg-white'>
                                <Link to="tel:+917006100174" className="flex justify-center !font-semibold items-center text w-full py-4 btn rounded-[44px]" >
                                    Call on 7006100174 to Book Slot
                                </Link>
                            </div>:<h1>Generating Invoice...</h1>}*/}
                        </div>



                        : AuthFlowOtp ? <Popup setopenInitialPopup={setopenInitialPopup} /> :

                            ""
                    }
                    {isAuth ? "" : <div className="flex justify-center items-center mt-10">
                        <button className="flex justify-center items-center !font-semibold !text-[17px] lg:max-w-[400px] w-full py-4 btn rounded-[44px]" onClick={() => { handleopenInitialPopup() }}>
                            Login to Continue
                        </button>
                    </div>}
                    {openInitialPopup ? <Popup setopenInitialPopup={setopenInitialPopup} /> : ""}

                </div>

            </div> : <Success successBookingDetails={successBookingDetails} selectedSportName={selectedSportName} />}



        </div>

    )


}

export default Home



