import React, { useContext, useEffect, useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import { AuthContext } from "../Context/AuthProvider";

function DynamicPricings() {
  const { getAllSportssData, Sports, SetRangeValue, fetchAllSlotsPriceMgmt, nextDate,
    prevDate, Apiresponse, setapiresponse, Url, fetchAllSlotsPriceMgmtforDifferentDates } = useContext(AuthContext);
  useEffect(() => {
    getAllSportssData()
  }, [])
  const [selectedsport, setSelectedsport] = useState("None");
  const [startDate, setStartDate] = useState("None"); // State for start date
  const [endDate, setEndDate] = useState("None"); // State for end date
  const [selectedslot, setselecteslot] = useState({});

  const handleChange = async () => {
    // console.log(selectedsport);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(selectedslot);

    Object.keys(selectedslot).forEach((key) => {
      console.log(key);
    });
    let innerobj = {};
    const start = new Date(startDate);
    const end = new Date(endDate);

    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      innerobj[formattedDate] = selectedslot;
    }
    let outerobj = {};
    outerobj[selectedsport.sportId] = innerobj;
    // console.log(innerobj);
    // console.log(outerobj);
    SetRangeValue(outerobj);
    setSelectedsport("None")
    setStartDate("None")
    setEndDate("None")
    setselecteslot({})

  };
  const handleinputChange = (val, key, name) => {
    // console.log(val);
    // console.log(key);
    // console.log(name);
    setselecteslot((prev) => {
      const updatedSlot = { ...prev };

      updatedSlot[key] = {
        ...prev[key],
        [name]: val,
      };
      return updatedSlot;
    });
  };
  const handlesportChnage = (val) => {
    setSelectedsport(val);
    // console.log(selectedsport);
  };
  const handleStartDate = (val) => {
    setStartDate(val);
  };
  const handleEndDate = (val) => {
    setEndDate(val);
  };
  const handleCheck = (e, key, name) => {
    // console.log(key);
    // console.log(name);
    // console.log(e.target.checked);
    const isChecked = e.target.checked;

    setselecteslot((prev) => {
      const updatedSlot = { ...prev };

      if (isChecked) {
        updatedSlot[key] = {
          ...prev[key],
          [name]: "",
        };
      } else {
        delete updatedSlot[key][name];
      }

      return updatedSlot;
    });
  };

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    let formattedHours = parseInt(hours, 10);
    const ampm = formattedHours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    formattedHours = formattedHours % 12 || 12;

    return `${formattedHours}:${minutes} ${ampm}`;
  }

  function getDateRange() {
    const currentDate = new Date();

    // Get today's date
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const today = `${year}-${month}-${day}`;

    // Get yesterday's date
    const yesterdayDate = new Date(currentDate);
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    const yesterdayYear = yesterdayDate.getFullYear();
    const yesterdayMonth = String(yesterdayDate.getMonth() + 1).padStart(2, '0');
    const yesterdayDay = String(yesterdayDate.getDate()).padStart(2, '0');
    const yesterday = `${yesterdayYear}-${yesterdayMonth}-${yesterdayDay}`;

    // Get tomorrow's date
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const tomorrowYear = tomorrowDate.getFullYear();
    const tomorrowMonth = String(tomorrowDate.getMonth() + 1).padStart(2, '0');
    const tomorrowDay = String(tomorrowDate.getDate()).padStart(2, '0');
    const tomorrow = `${tomorrowYear}-${tomorrowMonth}-${tomorrowDay}`;

    return {
      today: today,
      yesterday: yesterday,
      tomorrow: tomorrow
    };
  }

  useEffect(() => {
    let currdates = getDateRange()
    fetchAllSlotsPriceMgmt(currdates.today, currdates.yesterday, currdates.tomorrow)
  }, [])

  const [datagstate, setDatagState] = useState({});
  let datag = {};

  const BulkUpdatePriceChange = (sportid, date, slot, price, turf) => {
    const updatedResponse = Apiresponse.map((apiSport) => {
      if (apiSport.sportId === sportid) {
        const updatedSlots = apiSport.slotData.map((slotData) => {
          if (slotData.slotName === slot) {
            const updatedTurfs = slotData.Turfs.map((turfData) => {
              if (turfData.name === turf) {
                return { ...turfData, price: price };
              }
              return turfData;
            });
            return { ...slotData, Turfs: updatedTurfs };
          }
          return slotData;
        });
        return { ...apiSport, slotData: updatedSlots };
      }
      return apiSport;
    });

    setapiresponse(updatedResponse);
    if (!datag.hasOwnProperty(sportid)) {
      datag[sportid] = {};
    }
    if (!datag[sportid].hasOwnProperty(date)) {
      datag[sportid][date] = {};
    }
    if (!datag[sportid][date].hasOwnProperty(slot)) {
      datag[sportid][date][slot] = {};
    }
    datag[sportid][date][slot][turf] = price;
    setDatagState(datag);
  };

  const HitBulkUpdateAPI = async () => {
    console.log(datagstate);
    try {
      const response = await fetch(
        `${Url}/sports/bulkUpdatePrice/${localStorage.getItem(
          "adminToken"
        )}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datagstate),
        }
      );

      const json = await response.json();
      console.log(json);
      if (json.Status) {
        HitPriceChangeByDate(calenderdate);
      } else {
      }
      setDatagState({});
    } catch { }
  };

  function CurrentDate() {
    const currentDate = new Date();

    // Get year, month, and day
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
  const [calenderdate, setcalenderdate] = useState(CurrentDate());
  const HitPriceChangeByDate = (date) => {
    setcalenderdate(date);
    fetchAllSlotsPriceMgmtforDifferentDates(date);
  };

  console.log(Apiresponse)

  return (

    <div className='max-w-[1280px] lg:w-[1280px] mx-auto mt-5 max-md:px-5'>
      <h4>Date Range Update</h4>
      <div className="grid grid-cols-4 mt-5 max-md:grid-cols-1" style={{ width: "100%" }}>
        <div >
          <Dropdown>
            <Dropdown.Toggle variant="success">
              {selectedsport === "None" ? "Select sport" : selectedsport.sportName}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Sports.map((val, idx) => (
               <div>
                 <Dropdown.Item key={idx} onClick={() => handlesportChnage(val)}>
                  {val.sportName}
                </Dropdown.Item><br></br>
               </div>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {/* <select name="cars" id="cars">
            <option value="" selected disabled hidden>Choose sport</option>
              {Sports.map((val, idx) => (
                  
                  <option key={idx} onCh={() => handlesportChnage(val)} value={val.sportName}>{val.sportName}</option>
                ))}
              
          </select> */}
        </div>
        <div >
          <div className='flex items-center max-md:justify-between' >
            <label style={{ marginRight: 10 }}>From: </label>
            <input
              type="date"
              className='py-[.1rem] px-[1rem] border-2'
              value={startDate}
              onChange={(e) => handleStartDate(e.target.value)}
            />
          </div>
        </div>
        <div className="">
          <div className='flex items-center max-md:justify-between'>
            <label style={{ marginRight: 10 }}>To: </label>
            <input
              type="date"
              className='py-[.1rem] px-[1rem] border-2'
              value={endDate}
              onChange={(e) => handleEndDate(e.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center">
          <div>
            <button className="btn py-[.5rem] px-[1rem] h-100" onClick={() => handleChange()}>Submit</button>
          </div>
        </div>

      </div>
      {
        selectedsport !== "None" && selectedsport.slots && (
          <div>
            
            <br />

            <div className="row mt-5" >
              {Object.entries(selectedsport.slots)
                .sort(([keyA, valueA], [keyB, valueB]) => new Date(valueA.start) - new Date(valueB.start))
                .map(([key, value], idx) => (
                  <div className="col-sm-4" style={{ border: "1px solid grey", borderRadius: "5px", marginTop: "3px" }}>
                    <label htmlFor={key}>{formatTime(selectedsport.slots[key].start)} - {formatTime(selectedsport.slots[key].end)}</label>
                    {selectedsport !== "None" && selectedsport?.slots && (
                      <div>
                        {selectedsport?.turfs.map((val, idx) => (
                          <>
                            <input
                              type="checkbox"
                              className='py-[.1rem] px-[1rem] border-2'
                              onChange={(e) => handleCheck(e, key, val.name)}
                            />
                            <label htmlFor={val?.name}> {val?.name}</label>
                            {selectedslot[key] &&
                              selectedslot[key][val.name] !== undefined ? (
                              <input
                                height="10rem"
                                width="10rem"
                                className='py-[.1rem] px-[1rem] border-2'
                                value={selectedslot[key][val.name]}
                                onChange={(e) =>
                                  handleinputChange(e.target.value, key, val.name)
                                }
                              />
                            ) : (
                              ""
                            )}

                            <br />
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                ))}


            </div>
          </div>
        )
      }

      <div className='mt-5'>
        <div className="flex justify-between items-center max-sm:flex-col max-md:justify-center">
          <div className='flex flex-1 gap-2 max-md:justify-between'>
            <label>Go to: </label>
            <input
              className="form-control py-[.1rem] px-[1rem] border-2 rounded-sm"
              style={{ marginRight: "10px" }}
              type="date"
              value={calenderdate}
              onChange={(e) => {
                HitPriceChangeByDate(e.target.value);
              }}
            />
          </div>

          <div className="button_prev_next  flex justify-center flex-1">
            <i
              className="fas fa-chevron-circle-left m-2  text-[#5092FB]"
              style={{ fontSize: "24px" }}
              onClick={() => {
                HitPriceChangeByDate(prevDate);
              }}
            >

            </i>
            <i
              className="fas fa-sync m-2  text-[#5092FB]"
              style={{ fontSize: "24px" }}
              onClick={() => {
                HitPriceChangeByDate(CurrentDate());
              }}
            >

            </i>
            <i
              className="fas fa-chevron-circle-right m-2 text-[#5092FB]"
              style={{ fontSize: "24px" }}
              onClick={() => {
                HitPriceChangeByDate(nextDate);
              }}
            >

            </i>
          </div>
          <div className='flex justify-end flex-1'>
            <button
              className="btn py-[.5rem] px-[1rem]"
              onClick={() => {
                HitBulkUpdateAPI();
              }}
            >
              Bulk Update
            </button>
          </div>
        </div>
      </div>

      <div className="">
        {Apiresponse.map((sport, index) => {
          return (
            <div key={index} className='scroll' style={{
              overflow: "auto",
              whiteSpace: "nowrap",
            }}>
              <table className=" mt-4">
                <tbody>
                  <tr className='heading' style={{ border: "1px solid #AF5800" }}>
                    <th className="!text-[#fff] py-[.5rem]" style={{ width: "400px" }}> {/* Fixed width for sport name */}
                      {sport.sportName}-Slots / Turfs
                    </th>
                    {sport.slotData
                      .sort((a, b) => a.slotStart.localeCompare(b.slotStart))
                      .map((slots) => (
                        <th className='text-center !text-white py-[.5rem]' style={{ width: "400px" }}> {/* Fixed width for other cells */}
                          {formatTime(slots.slotStart)}-{formatTime(slots.slotEnd)}
                        </th>
                      ))}
                  </tr>
                </tbody>
                <tbody>
                  {sport.slotData.map((slot, outerIndex) => (
                    <React.Fragment key={outerIndex}>
                      {outerIndex === 0 &&
                        slot.Turfs.map((turf, innerIndex) => (
                          <tr key={innerIndex}>
                            <td className="fixed-room-type">{turf.name}</td>
                            {sport.slotData.map((slt, index) => (
                              <td key={index}>
                                <input
                                  onChange={(e) => {
                                    BulkUpdatePriceChange(
                                      sport.sportId,
                                      calenderdate,
                                      slt.slotName,
                                      e.target.value,
                                      turf.name
                                    );
                                  }}
                                  style={{
                                    border: "none",
                                    textAlign: "center",
                                  }}
                                  value={slt.Turfs[innerIndex].price}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>

    </div >
  )
}

export default DynamicPricings