import React from 'react';
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import B3 from "../assets/footNet.svg";
import banner from "../assets/soccer-game-concept 1.svg";



const Banner = () => {


    const data = [
        B3
    ]
    return (
        <div>
            <div className='lg:hidden mx-auto w-full px-5' >
                <Swiper
                    loop={true}
                    autoplay={{
                        delay: 4000,
                    }}
                    pagination={{
                        el: ".navigation_Dot",
                    }}
                    navigation={{
                        prevEl: ".prev-btn",
                        nextEl: ".next-btn",
                    }}
                    modules={[Pagination, Autoplay, Navigation]}
                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                    }}
                >
                    {data.map((img, i) => {
                        return (
                            <SwiperSlide >


                                <img key={i} src={img} alt={`slide${i}`} style={{ width: "100%", objectFit: "cover" }} />

                            </SwiperSlide>
                        );
                    })}
                </Swiper>

                <div className='flex justify-center gap-2 mt-4'>


                    <div className="flex justify-center items-center">
                        <div className='navigation_Dot'></div>
                    </div>
                </div>
            </div>
            <div className='max-md:hidden lg:max-w-[1280px] mx-auto rounded-[20px] overflow-hidden'>
                <img src={banner} alt='banner' />
            </div>
        </div>
    )
}

export default Banner