import React from 'react';
import { Helmet } from "react-helmet";
import CancellationBann from "../assets/side-view-man-playing-soccer 1 (1).png";
import PritermCon from '../components/PritermCon';

const Cancellation = () => {

    const CancellationData = [
        {
            "heading": "Cancellation Process:",
            "para": "Bookings at LoneStar Arena can be cancelled through verbal or written communication. This includes phone calls, emails, or in-person requests at the arena's reception.",
            "para2": "The cancellation request must be clearly communicated, specifying the booking details and reason for cancellation."
        },
        {
            "heading": "Misbehavior Policy:",
            "para": "Clients who exhibit any form of misbehavior or misconduct will be permanently banned from entering and playing at the LoneStar Arena.",
            "para2": "The decision regarding misbehavior and subsequent banning is at the sole discretion of the LoneStar Arena management."
        },
        {
            "heading": "Booking Reservations:",
            "para": "The management of LoneStar Arena reserves the right to confirm or cancel bookings up to 4 hours before the scheduled time.",
            "para2": "Clients are advised in their own interest to check the status of their bookings within this period to avoid any inconvenience."
        },


    ]


    const CancellationImg = [CancellationBann]
    return (
        <div>

            {/* <ComBanner
                bannerImage={CancellationImg}
                heading={"Cancellation"}

            /> */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hassle-Free Bookings at Lone Star Arena</title>
                <meta name="description" content="Understand LoneStar Kashmir FC's cancellation policy for smooth and stress-free booking experiences." />
            </Helmet>
            <h1 className='text-[42px] font-bold text-[#AF5800] text-center mt-10'>Cancellation Policy</h1>

            <div>

                {CancellationData.map((cancellationData, index) => (
                    <PritermCon data={cancellationData} />
                ))}

            </div>
        </div>
    )
}

export default Cancellation