import React from 'react';
import { Link } from 'react-router-dom';
import Insta from "../assets/Group (2).svg";
import Logo from "../assets/Lonestar-Fc-Logo.png";
import Face from "../assets/g12.svg";
const Footer1 = () => {
    return (
        <div className="flex flex-col justify-center px-px mt-5">
            <div className="flex flex-col pt-9 bg-indigo-50 max-md:max-w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 justify-between items-start self-center px-5 w-full max-w-[1280px] max-md:flex-wrap max-md:max-w-full">
                    <div className=" flex flex-col self-stretch text-lg leading-7 text-center text-zinc-800">
                        <img
                            loading="lazy"
                            src={Logo}
                            className="self-center max-w-full aspect-[1.14] w-[126px]"
                        />
                        <div className="mt-4 text-[18px]">
                            Lone Star Complex, Baghat Chowk,190005 Srinagar Kashmir
                        </div>
                    </div>
                    <div className='col-span-2 flex max-md:flex-col justify-center max-md:gap-5'>
                        <div className="flex flex-col text-lg lg:w-[50%] ml-20 max-md:ml-0 leading-6 text-zinc-800">
                            <div className="text-xl font-bold leading-9 text-amber-700">
                                Links
                            </div>
                            <Link to="/" className="mt-4" onClick={() => window.scrollTo(0, 0)}>Home</Link>
                            <Link to="/about" className="mt-4" onClick={() => window.scrollTo(0, 0)}>About us</Link>
                            <Link to="/contact" className="mt-4" onClick={() => window.scrollTo(0, 0)}>Contact us</Link>
                        </div>
                        <div className="flex flex-col lg:w-[50%] text-lg leading-6 text-zinc-800">
                            <div className="text-xl font-bold leading-9 text-amber-700">
                                Policy
                            </div>
                            <Link to="/privacy" className="mt-4" onClick={() => window.scrollTo(0, 0)}>Privacy Policy</Link>
                            <Link to="/terms" className="mt-4" onClick={() => window.scrollTo(0, 0)}>Terms & Conditions</Link>
                            <Link to="/cancellation" className="mt-4" onClick={() => window.scrollTo(0, 0)}>Cancellation & Refund</Link>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-xl font-bold leading-9 text-amber-700">
                            Subscribe Our Newsletter
                        </div>
                        <div className="flex  pl-6 mt-4 text-lg leading-6 text-justify bg-white rounded-[44px] text-zinc-400 max-md:pl-5">
                            <input placeholder='Your Email Address*' className="flex-auto my-auto outline-none" />
                            <button>
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e5d32f1826b8eaf5423e9144b0d36a3f80fc36446feca3864dc182e4dbcf46aa?"
                                    className="shrink-0 aspect-[1.15] w-[55px]"
                                />
                            </button>
                        </div>
                        <div className="flex gap-4 pr-20  mt-6 max-md:pr-5">
                            <Link to="https://www.instagram.com/lonestar_kashmirfc?igsh=Ymk1eWFoM2Q1eDFi " target='_blank'>
                                <img src={Insta} alt='instagram' />
                            </Link>
                            <Link to="https://www.facebook.com/lonestarkfc?mibextid=ZbWKwL " target='_blank'>
                                <img src={Face} alt='instagram' />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center px-16 py-3.5 mt-10 w-full text-base leading-6 text-white bg-blue-400 max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-3 max-md:flex-wrap">
                        <div className="flex gap-1.5">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4e3a2e41f9b53988f39f2c0eaa7b6feaaa200040768321c5a3bd2df282f26634?"
                                className="shrink-0 aspect-square w-[22px]"
                            />
                            <div>2024 Lone Star</div>
                        </div>
                        <div className="text-center">|</div>
                        <div>All Rights Reserved</div>
                        <div className="text-center">|</div>
                        <div >Designed & Developed by <Link to="https://eazotel.com/" target='_blank'>Eazotel</Link></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer1