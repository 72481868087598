import React, { useContext, useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { MdLogin } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from '../Context/AuthProvider';
import Logo from "../assets/Lonestar-Fc-Logo.png";
import NavPop from './NavPop';
import Popup from "./popup";
const Navbar = () => {

    const location = useLocation()
    const { isOpen, setIsOpen, isAuth } = useContext(AuthContext);
    const [closeLoginPopup,setcloseLoginPopup] = useState(false)

    const handleHamClick = () => {
        setIsOpen(!isOpen);
    }
    return (
        <div>
            <div className=" flex justify-center px-5 py-2 nav_background relative" >
                <div className='max-w-[1280px] lg:w-[1280px] max-md:w-full flex justify-between mx-auto gap-5 items-center' >
                    <div className='flex items-center gap-10'>
                        <Link to="/" className='w-[88px]'>
                            <img
                                loading="lazy"
                                src={Logo}
                                alt='logo'
                                className="shrink-0 aspect-[1.14] w-full"
                            />
                        </Link>
                        {/* <Link to="/" style={{color:"black",fontSize:"600"}}>
                            Home
                        </Link> */}
                    </div>


                    <div className='max-md:hidden flex items-center gap-10'>
                        <Link to="/" className={`text-[18px] ${location.pathname === "/" ? "text-[#AF5800]" : "text-[#333333]"} `}>Home</Link>
                        <Link to="/about" className={`text-[18px] ${location.pathname === "/about" ? "text-[#AF5800]" : "text-[#333333]"} `}>About</Link>
                        <Link to="/contact" className={`text-[18px] ${location.pathname === "/contact" ? "text-[#AF5800]" : "text-[#333333]"} `}>Contact</Link>
                    </div>
                    {isAuth ? <div>
                        <GiHamburgerMenu size={30} className='icon' onClick={handleHamClick} />
                    </div> :
                        <Link onClick={()=>{setcloseLoginPopup(true)}} className="flex items-center gap-3 leading-7 whitespace-nowrap ">
                            <MdLogin size={22} color='#5092FB' />
                            <div className='text-[18px] text-[#333333]'>Login</div>
                        </Link>

                    }
                </div>


            </div>

            {isOpen ?

                <NavPop />
                : ""}

            {closeLoginPopup?<Popup setopenInitialPopup={setcloseLoginPopup} />:""}

        </div>
    )
}



export default Navbar