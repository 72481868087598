import React from 'react';
import { Link } from 'react-router-dom';



import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ComBanner = ({ bannerImage, heading, subHeading, button }) => {

    // const bannerImages = ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
    return (




        <Swiper
            autoplay={{
                delay: 5000,
            }}
            loop={true}
            navigation={{
                nextEl: ".next-testimonal",
                prevEl: ".prev-testimonal",
            }}
            pagination={{
                el: "#testimonal-pagination",
            }}
            modules={[Navigation, Pagination, Autoplay]}
            slidesPerView={1}
        >
            {bannerImage.map((Data, index) => {
                return (
                    <SwiperSlide key={index}>
                        <div className="flex overflow-hidden relative flex-col justify-center text-xl font-medium text-white max-w-[1600px] mx-auto md:h-[760px] max-h-[760px]">

                            <img
                                loading="lazy"
                                src={Data}
                                className="object-cover absolute inset-0 size-full"
                            />
                            <div className=" md:h-[760px] max-md:py-20 max-h-[760px] flex relative flex-col items-center justify-center w-full bg-black bg-opacity-30 max-md:px-5 max-md:max-w-full">
                                {heading && <div className=" text-6xl font-bold text-center leading-[72px] w-[854px] max-md:max-w-full max-md:text-4xl max-md:leading-[53px]">
                                    {heading}
                                </div>}
                                {subHeading && <div className="mt-3 leading-7 text-center w-[695px] max-md:max-w-full">
                                    {subHeading}
                                </div>}
                                {button && <Link to="/book" className='w-[150px] mt-4 text-center py-2 px-5 font-semibold text-white rounded-3xl bg-[#5092FB]'>Book Now</Link>}
                            </div>
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    )
}

export default ComBanner