import React from 'react'
import { Link } from 'react-router-dom'
import whoweare from "../assets/DSC05333.jpg"
// import Football from "../assets/Rectangle 269.png"
// import BoxCricket from "../assets/Rectangle 270.png"

import bannImg1 from "../assets/compressed/DSC05298_1_11zon.jpg"
import bannImg3 from "../assets/compressed/DSC05370_3_11zon.jpg"
import bannImg2 from "../assets/compressed/DSC05492_6_11zon.jpg"
import ComAboutCard from '../components/ComAboutCard'
import ComBanner from '../components/ComBanner'
import SportsCard from '../components/SportsCard'

const HomePage = () => {



    const sportsData = [
        {
            "image": "https://cdn.britannica.com/51/190751-131-B431C216/soccer-ball-goal.jpg",
            "heading": "Football",
            "para": "Football, also called association football or soccer, is a game involving two teams of 11 players who try to maneuver the ball into the other team's goal without using their hands or arms."
        },
        {
            "image": "https://media.hudle.in/photos/53265",
            "heading": "Box Cricket",
            "para": "Box cricket is a game of cricket, just with a few twists. It’s fast-paced, fun, and ready to be enjoyed by players of all ages. If you’re hunting for a unique way to experience cricket and take your game to the next level, this sport is for you."
        },
        // {
        //     "image": NetCricket,
        //     "heading": "Net Cricket",
        //     "para": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        // },
        // {
        //     "image": PicBall,
        //     "heading": "Pick Ball",
        //     "para": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        // },

    ]


    const SwiperData = [
        bannImg2, bannImg3, bannImg1
    ]
    return (
        <div className=''>

            <ComBanner
                bannerImage={SwiperData}
                heading={"Premier Sporting Experience in Kashmir"}
                subHeading={""}
                button={true}

            />
            <div className='mt-10'>
                <ComAboutCard
                    aboutImg={whoweare}
                    heading={"Who We Are"}
                    paragraph={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}
                    button={true}
                />
            </div>

            <div className='mt-10'>
                <h1 className='text-[42px] text-center font-bold text-[#AF5800]'>Sports</h1>
            </div>
            {/* <div className='mt-10 !max-w-[1280px] mx-auto   gap-10 px-5 flex justify-center'> */}
            <div className='mt-10 !max-w-[700px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 px-5 '>

                {sportsData.map((sportData, index) => (
                    <SportsCard key={index} data={sportData} />
                ))}
            </div>



            <div className='mt-10 flex justify-center'>
                <Link to="/book" className='w-[150px] text-center py-2 px-5 font-semibold text-white rounded-3xl bg-[#5092FB]'>Book Now</Link>
            </div>
        </div>

    )
}

export default HomePage