import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../Context/AuthProvider';
import BookingFilter from '../components/BookingFilter';
// import "../style/ReservationDeskTable.css";
import "../style/OurBooking.css";

const BookingPopup = lazy(() => import("../components/BookingPopup"));

function OurBookings() {
  const { Allbookings, getAllBookingsData, Url, bookingPopUp, setBookingPopUp } = useContext(AuthContext);
  useEffect(() => {
    getAllBookingsData()
  }, [])


  const [setLoneStarBookings] = useState([]);
  const [fetchLoneStarBookings] = useState([]);



  const [Infodata, setInfodata] = useState()

  const ChangeCheckinOutStatus = async (bookingid, checkin, checkout) => {
    try {
      const response = await fetch(
        `${Url}/sports/changeStatusCheckInCheckOut/${localStorage.getItem("adminToken")}/${bookingid}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "isCheckedIn": checkin,
            "isCheckedOut": checkout

          }),
        }
      );

      const json = await response.json();
      getAllBookingsData()

    } catch {
      // alert("Some Problem update token");
    }
  }




  const handleInfoPopup = (data) => {
    setInfodata(data);
    setBookingPopUp(!bookingPopUp);
  };
  //   const handleInfoPopupII = (data) => {
  //     setInfoData(data);
  //     setOpenInfoPopUpII(!openInfoPopUpII);
  //   };
  //   const handleInfoPopupIII = (data) => {
  //     setchechinData(data);
  //     setopenInfoPopUpIII(!openInfoPopUpIII);
  //   };

  const bookingData = []

  function convertDateFormat(inputDate) {
    const parts = inputDate.split('-');
    if (parts.length === 3) {
      const [year, month, day] = parts;
      // Create a new Date object with the given parts
      const dateObject = new Date(`${year}-${month}-${day}`);
      // Format the date as 'dd-mm-yyyy'
      const formattedDate = `${String(dateObject.getDate()).padStart(2, '0')}-${String(dateObject.getMonth() + 1).padStart(2, '0')}-${dateObject.getFullYear()}`;
      return formattedDate;
    } else {
      // Handle invalid input
      console.error('Invalid date format');
      return inputDate;
    }
  }

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    let formattedHours = parseInt(hours, 10);
    const ampm = formattedHours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    formattedHours = formattedHours % 12 || 12;

    return `${formattedHours}:${minutes} ${ampm}`;
  }

  const HitPaidAmount = async (dar, bookingid, orderid) => {
    if (dar == "Done") {
      const response = await fetch(`${Url}/sports/makeBookingSuccess/${localStorage.getItem("adminToken")}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "sportBookingId": bookingid,
          "orderId": orderid
        }),
      });
      await response.json()
      getAllBookingsData()
    }
    else if (dar == "Cancel") {
      const response = await fetch(`${Url}/sports/deleteBooking`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "bookingId": bookingid
        }),
      });
      await response.json()
      getAllBookingsData()
    }

  }


  return (


    <div className='lg:max-w-[1280px] max-w-full mx-auto mt-5 mb-5'>
      <BookingFilter />
      {bookingData === null ? (
        <div className=''
          style={{
            // display: "flex",
            // width: "80vw",
            // height: "70vh",
            // justifyContent: "center",
            // alignItems: "center",
            // justifyItems: "center",
          }}
        >
          <h1 color="#E65502">Loading...</h1>
        </div>
      ) : (
        <>
          <div>
            <div
              className="tble-head d-flex"
              style={{}}
            >
              <div
                className="tbl-inr mt-5 d-flex"
                style={{
                  justifyContent: "space-around",
                  height: "100%",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                {/* <span className='max-md:px-5'>Total Bookings </span>
                <span>
                  {Allbookings != null ? Allbookings.length : 0}
                </span> */}
              </div>
            </div>
            <div className='ourbooking mt-5'>
              <table className="ourbookingTable">
                <thead >
                  <tr className="">
                    <th>Booking Id</th>
                    <th>Guest Name</th>
                    <th>Playing Date</th>
                    <th>Email-Id</th>
                    <th>Phone</th>
                    <th>Total Price</th>
                    <th>Remaining Amount</th>
                    <th>Sports</th>
                    <th>Start Time</th>
                    <th>Duration</th>
                    <th>Turf Name</th>
                    <th>Payment Status</th>
                    {/* <th>Checked In</th>
                    <th>Checked Out</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody >
                  {Allbookings.map((row, rowIndex) => (
                    row?.payment?.Status !== "PENDING" ? <tr key={rowIndex}>
                      <td >
                        {" "}
                        <a style={{ color: "darkblue", cursor: "pointer" }} onClick={() => { handleInfoPopup(row) }}>{row.sportBookingId}</a>{" "}
                      </td>
                      <td >
                        {row.guestInfo.Name}
                      </td>

                      <td>{convertDateFormat(row.bookingDate)}<br></br>
                      </td>
                      <td>
                        {row.guestInfo.Email} <br></br>

                      </td>
                      <td>{row.guestInfo.Phone}</td>

                      <td>₹ {row.price.totalAmount} </td>
                      <td>₹ {Number(row.price.totalAmount) - Number(row.price.amountPaid)}</td>
                      <td>{row.sportId === "RV7p97oHCriyEY6pLxwHEz" ? "Box Cricket" : "Football"}</td>
                      <td>{formatTime(row.bookedSlots[0].slotstart)}</td>
                      <td>{row.duration}</td>
                      <td>{row.bookedSlots[0].turfName}</td>
                      <td>
                        {row.payment.Status === "PENDING" ? (
                          <span className="badge bg-danger">
                            {row.payment.Status}
                          </span>
                        ) : row.payment.Status === "ADVANCED" ? (
                          <span className="badge bg-warning">
                            {row.payment.Status}
                          </span>
                        ) : row.payment.Status === "CANCELLED" ? (
                          <span className="badge bg-secondary">
                            {row.payment.Status}
                          </span>
                        ) : row.payment.Status === "REFUND" ? (
                          <span className="badge text-bg-info">
                            {row.payment.Status}
                          </span>
                        ) : (
                          <span className="badge bg-success">
                            {row.payment.Status}
                          </span>
                        )}
                      </td>
                      {/* <td style={{ cursor: "pointer" }}>
                      {row.payment.Status === "CANCELLED" ? (
                        "-"
                      ) : row.isCheckedIn === true ? (
                        <span

                          className="badge bg-success"
                        >
                          Checked In
                        </span>
                      ) : (
                        <button
                          className="btn py-[.3rem] px-[1rem]"
                          onClick={() => { ChangeCheckinOutStatus(row.sportBookingId, "true", "false") }}
                        >
                          Check In
                        </button>
                      )}
                    </td>
                    <td>
                      {row.payment.Status === "CANCELLED" ? (
                        "-"
                      ) : row.isCheckedIn === true &&
                        row.isCheckedOut === true ? (
                        <span className="badge bg-danger">Checked Out</span>
                      ) : row.isCheckedIn === false ? (
                        "-"
                      ) : (
                        <button
                          className="btn py-[.3rem] px-[1rem]"
                          onClick={() => { ChangeCheckinOutStatus(row.sportBookingId, "true", "true") }}
                        >
                          Check Out
                        </button>
                      )}
                    </td> */}
                      <td>

                        <select onChange={(e) => { HitPaidAmount(e.target.value, row.sportBookingId, row.payment.RefNo) }}>
                          {row.payment.Status === "CANCELLED" ? "" : <option value="Partial">Partial Paid</option>}
                          <option value="Done">FULL PAID</option>
                          <option value="Cancel">Cancel Booking</option>
                        </select>

                      </td>

                      {/* <spna style={{cursor:"pointer"}} className={'badge_success'} onClick={()=>{PaidBookingFun(row.sportBookingId,row.payment.RefNo)}}>Done</spna> */}
                    </tr> : ""
                  )).reverse()
                  }


                </tbody>
              </table>
            </div>

            {/* {openInfoPopUp ? <InfoPopup Infodata={Infodata} setopenInfoPopUp={setopenInfoPopUp} /> : ""} */}

            {/* {openInfoPopUpII ? <InfoPopUpII /> : ""} */}

            {/* {openInfoPopUpIII ? (
            <InfoPopUpIII
              infoData={chechinData}
              setopenInfoPopUpIII={setopenInfoPopUpIII}
              openInfoPopUpIII={openInfoPopUpIII}
            />
          ) : (
            ""
          )} */}
          </div>
        </>
      )}

      {bookingPopUp ? <Suspense fallback={"Loading..."}>
        <BookingPopup bookingInfo={Infodata} />
      </Suspense> : ""}
    </div>
  )
}

export default OurBookings