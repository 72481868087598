import React, { useContext, useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import { AuthContext } from '../Context/AuthProvider';
const Popup = ({ setopenInitialPopup }) => {

    const { Url, setisAuth, AuthFlowOtp, setAuthFlowOtp, setIsDashboard,setloogedinAdmin } = useContext(AuthContext)
    const [alreadyRegistered, setalreadyRegistered] = useState(true)
    const [number, setnumber] = useState("")
    const [otp, setotp] = useState("")
    const [sendotp, setsendotp] = useState(false)
    const [Name, setName] = useState("")
    const [Email, setEmail] = useState("")

    const CheckEndUser = async (token) => {
        try {
            const response = await fetch(`${Url}/sports/checkenduser/${token}`, {
                method: 'GET',
                headers: {
                    Accept: "application/json",
                },

            })
            const json = await response.json()
            if (json.Status === true) {
                setisAuth(true)
                setIsDashboard(json.isAdmin)
                if(json.isAdmin){
                    try{
                        const response1 = await fetch(`${Url}/sports/checkauthusertoken/${token}`, {
                            method: 'GET',
                            headers: {
                                Accept: "application/json",
                            },
                        })
                        const json1 = await response1.json()
                        
                        if(json1.Status){
                            localStorage.setItem("adminToken",json1.Token);
                            setloogedinAdmin(json1.User.isAdmin);
                        }
                        else{
                            setIsDashboard(false)
                        }
                    }
                    catch{
                        console.log("hey")
                    }
                }
            }
            else {
                setisAuth(false)
                localStorage.clear()
            }
        }
        catch (err) {
            console.log("Error:", err)
        }
    }

    const SendOtpFunction = async (name, email) => {

        if (name === "" || email === "") {
            alert("Please Enter Fields")
        }
        else {
            setsendotp(true)
            setalreadyRegistered(true)
            try {
                const response = await fetch(`${Url}/sports/signupenduser`, {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                    },
                    body: JSON.stringify(
                        {
                            "number": number,
                            "Name": name,
                            "Email": email
                        }
                    )
                })
                const json = await response.json()
                if (json.Status === true) {
                    console.log("sent")
                    setName("")
                    setEmail("")

                }
                else {
                    alert("Some Problem Occured")
                }
            }
            catch (err) {
                console.log("Error:", err)
            }
        }

    }
    const VerifyOtpFunction = async () => {
        try {
            const response = await fetch(`${Url}/sports/signinenduser`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(
                    {
                        "number": number,
                        "otp": otp
                    }
                )
            })
            const json = await response.json()
            if (json.Status === true) {
                localStorage.setItem("userAuth", json.Token)
                setisAuth(true)
                CheckEndUser(json.Token)
                setopenInitialPopup(false);
            }
            else {
                alert("Something")
            }
        }
        catch (err) {
            console.log("Error:", err)
        }
    }



    const VerifyPhoneNumber = async () => {
        if (alreadyRegistered === false) {
            SendOtpFunction(Name, Email)
        }
        else {
            try {
                const response = await fetch(`${Url}/sports/checkenduser/number/${number}`, {
                    method: 'GET',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    }
                })
                const json = await response.json()
                if (json.Status === true) {
                    SendOtpFunction("Test", "Test@gmail.com")
                }
                else {
                    setalreadyRegistered(false)
                }
            }
            catch (err) {
                console.log("Error:", err)
            }
        }
    }


    const handleCloseClick = () => {
        setAuthFlowOtp(false);
        setopenInitialPopup(false);
    }
    return (
        <div className='fixed top-0 left-0 z-10 w-full max-md:px-5 h-[100%] flex justify-center items-center bg-[#000000A6]'>
            <div className=" fixed flex flex-col items-center py-3 px-3 bg-white rounded-2xl max-w-[586px] lg:w-[400px]">
                <div className='flex w-full justify-end'>
                    <RxCross2 style={{ cursor: "pointer" }} size={24} onClick={handleCloseClick} color='#333333' />
                </div>
                <div className=' flex flex-col px-5 max-md:px-5 w-full'>
                    <div className="text-[20px] font-[500] !bg-transparent  !text-[#333333]">
                        Enter your mobile number
                    </div>
                    <input
                        type='number'
                        value={number}
                        onChange={(e) => { setnumber(e.target.value) }}
                        placeholder='Mobile Number'
                        readOnly={sendotp ? true :false}
                        className="justify-center self-stretch px-4 py-1 mt-2 rounded-[8px] border-brown max-md:max-w-full "
                    />
                    {alreadyRegistered ? "" : <>
                        <p className='text-[16px] !text-[#AF5800] !bg-transparent'>Looks like new user. Please register here</p>
                        <div className="text-[20px] font-[500] !bg-transparent !text-[#333333] mt-2">
                            Full Name
                        </div>
                        <input
                            type='text'
                            value={Name}
                            onChange={(e) => { setName(e.target.value) }}
                            placeholder='Name'
                            className="justify-center self-stretch px-4 py-1 rounded-[8px]  border-brown max-md:max-w-full"
                        />
                        <div className="text-[20px] font-[500] !bg-transparent !text-[#333333] mt-2">
                            Email ID
                        </div>
                        <input
                            type='email'
                            value={Email}
                            onChange={(e) => { setEmail(e.target.value) }}
                            placeholder='abc@gmail.com'
                            className="justify-center self-stretch px-4 py-1 rounded-[8px] border-brown max-md:max-w-full"
                        />
                    </>}

                    {sendotp ? <input type='number' value={otp} onChange={(e) => { setotp(e.target.value) }} placeholder='Enter OTP' className="mt-3 border-none justify-center self-stretch px-4 py-1 border-brown rounded-[8px]  max-md:max-w-full" /> : ""}
                    {sendotp ?
                        <div>
                            <p className='text-[16px] !text-[#AF5800] !bg-transparent'>Otp sent to the Entered Number</p>
                            <div className='flex justify-center'>
                            <button onClick={() => { VerifyOtpFunction() }} type='submit' className="flex justify-center items-center  !font-semibold !text-[16px] py-[12px] px-[36px] btn rounded-[44px] mt-4" style={{ border: "1px solid #5092FB", backgroundColor: "#5092FB", color: "white" }} >
                                Verify OTP
                            </button>
                            
                        </div></div>

                        :alreadyRegistered?

                        <div className='flex justify-center'>
                            <button onClick={() => { VerifyPhoneNumber() }} type='submit' className="flex justify-center items-center font-semibold !text-[16px] py-[12px] px-[36px] btn rounded-[44px] mt-4" style={{ border: "1px solid #5092FB", backgroundColor: "#5092FB", color: "white" }} >
                                Send OTP
                            </button>
                        </div>:<div className='flex justify-center'>
                            <button onClick={() => { VerifyPhoneNumber() }} type='submit' className="flex justify-center items-center font-semibold !text-[16px] py-[12px] px-[36px] btn rounded-[44px] mt-4" style={{ border: "1px solid #5092FB", backgroundColor: "#5092FB", color: "white" }} >
                                Register Here
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Popup