import React from 'react'
import { Link } from 'react-router-dom'

const ComAboutCard = ({ aboutImg, heading, paragraph, button }) => {
    return (
        <div className='max-w-[1280px] mx-auto px-5'>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-10 max-md:gap-5'>
                <div className='flex items-center rounded-3xl overflow-hidden'>
                    <img src={aboutImg} alt='img' className='w-full h-[100%] object-cover' />
                </div>
                <div className='flex flex-col gap-2'>
                    <h1 className='text-[42px] font-bold text-[#AF5800]'>About Us</h1>
                    <p className='text-[18px]'>LoneStar Kashmir Football Club (LKFC), affectionately known as Lone Star, is a premier professional football club representing the heart of Jammu and Kashmir. Founded in 2013 by the visionary Iftikhar Ahmed Lone, the club swiftly rose to prominence, becoming a hope and opportunity for budding football talent across the valley. Affiliated with the Jammu & Kashmir Football Association (JKFA) and the All India Football Federation (AIFF), LKFC quickly became a prominent fixture in various state and regional tournaments.
                        <br /><br />Situated at Baghat Chowk, Srinagar, this state-of-the-art 7-a-side synthetic turf football field, approved by AIFF and FIFA, promises to revolutionize footballing experiences for players and fans alike. Crafted with precision, the LONESTAR ARENA has a prime location, exceptional drainage, and accessibility, ensuring optimal conditions for gameplay. Our commitment to player safety and comfort is reflected in incorporating advanced shock-absorption layers, enhanced lighting, and secure fencing to facilitate day and night matches.</p>

                    {button && <Link to="/about" className='w-[150px] py-2 px-5 font-semibold text-white rounded-3xl bg-[#5092FB] text-center'>Know More</Link>}
                </div>
            </div>
        </div>
    )
}

export default ComAboutCard