import axios from 'axios';
import { createContext, useEffect, useState } from 'react';

const AuthContext = createContext({});


function AuthProvider({ children }) {

    const [loogedinAdmin, setloogedinAdmin] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [isDashboard, setIsDashboard] = useState(false);
    const [auth, setAuth] = useState(false);
    const [date, setDate] = useState('')
    const [sportsData, setSportsData] = useState([])
    const [selectedSportName, setselectedSportName] = useState("")
    const [selectedSportId, setSelectedSportId] = useState(null);
    const [selectedSlotName, setselectedSlotName] = useState("")
    const [selectedSlotStart, setselectedSlotStart] = useState()
    const [selectedSlotEnd, setselectedSlotEnd] = useState()
    const [selectedSlottId, setSelectedSlotId] = useState(null);
    const [price, setPrice] = useState(0);
    const [turfName, setTurfName] = useState(null);
    const [counter, setCounter] = useState(1);
    const [AuthFlowOtp, setAuthFlowOtp] = useState(false)
    const [Profile, setProfile] = useState({})
    const [SlotsCounterData, setSlotsCounterData] = useState({})
    const [LoneStarBookings, setLoneStarBookings] = useState([]);
    const [addUserPopUp, setAddUserPopUp] = useState(false);
    const [bookingPopUp, setBookingPopUp] = useState(false);
    const [subtotalDict,setsubtotalDict] = useState({})

    // const Url = "http://127.0.0.1:5000";
    const Url = "https://nexon.eazotel.com";

    const [isAuth, setisAuth] = useState(false)

    const CheckEndUser = async (token) => {
        try {
            const response = await fetch(`${Url}/sports/checkenduser/${token}`, {
                method: 'GET',
                headers: {
                    Accept: "application/json",
                },

            })
            const json = await response.json()
            if (json.Status === true) {
                setisAuth(true)
                setIsDashboard(json.isAdmin)
                if (json.isAdmin) {
                    try {
                        const response1 = await fetch(`${Url}/sports/checkauthusertoken/${token}`, {
                            method: 'GET',
                            headers: {
                                Accept: "application/json",
                            },
                        })
                        const json1 = await response1.json()
                        if (json1.Status) {
                            localStorage.setItem("adminToken", json1.Token);
                            setloogedinAdmin(json1.User.isAdmin);
                            getAllSportssData()
                        }
                        else {
                            setIsDashboard(false)
                        }
                    }
                    catch {
                        console.log("hey")
                    }
                }
            }
            else {
                setAuth(false)
                localStorage.clear()
            }
        }
        catch (err) {
            console.log("Error:", err)
        }
    }



    useEffect(() => {
        const currentDate = new Date().toLocaleDateString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/\//g, '-');

        setDate(currentDate);

        if (localStorage.getItem("userAuth") !== null) {
            CheckEndUser(localStorage.getItem("userAuth"))
        }
    }, []);






    useEffect(() => {
        if (date) {
            getProfileofHotel()
            websiteData();
        }

    }, [date])

    const getCounterMaxProbablity = async (sportId, date) => {
        try {
            const response = await fetch(`${Url}/sports/countervalue`, {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, /",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "seid": "779f43ea-5ed0-43ee-86fb-c7d296a769cf",
                    "sportId": sportId,
                    "date": date
                }),
            });

            const json = await response.json();
            console.log(json)
            if (json.Status === true) {
                setSlotsCounterData(json.Data)

            } else {
                console.log("Problem")
            }
        }
        catch {
            console.log("Problem")
        }
    };

    const websiteData = async () => {

        // console.log(date)
        const host = `${Url}/sports/getSlotsForDate/779f43ea-5ed0-43ee-86fb-c7d296a769cf/${date}`

        try {
            const response = await axios.get(host);
            setSportsData(response.data.Data);
            
            if(selectedSportId===null){
                setselectedSportName(response.data.Data[1].sportName);
                setSelectedSportId(response.data.Data[1].sportId)
            }
            if(selectedSlottId===null){
                setselectedSlotName(response.data.Data[1].slotData[0].slotName)
                setselectedSlotStart(response.data.Data[1].slotData[0].slotStart)
                setselectedSlotEnd(response.data.Data[1].slotData[0].slotEnd)
                setSelectedSlotId(`${response.data.Data[1].slotData[0].slotStart}-${response.data.Data[1].slotData[0].slotEnd}`)
                getCounterMaxProbablity(response.data.Data[1].sportId, date)
            }

            
            setTurfName(null)

        } catch (error) {
            console.log("Error:", error)
        }
    }

    const getProfileofHotel = async () => {
        try {
            const response = await fetch(`${Url}/sports/getprofile/779f43ea-5ed0-43ee-86fb-c7d296a769cf`, {
                method: 'GET',
                headers: {
                    Accept: "application/json",
                },

            })
            const json = await response.json()
            if (json.Status === true) {
                setProfile(json.Details)
            }
            else {
                setAuth(false)
                localStorage.clear()
            }
        }
        catch (err) {
            console.log("Error:", err)
        }
    }

    const [usersMgmt, setusersMgmt] = useState([])
    const getUserManagementData = async () => {
        try {
            const response = await fetch(`${Url}/sports/${localStorage.getItem("adminToken")}`, {
                method: 'GET',
                headers: {
                    Accept: "application/json",
                },

            })
            const json = await response.json()
            console.log(json)
            if (json.Status === true) {
                setusersMgmt(json.Details)
            }
            else {
                setusersMgmt([])
            }
        }
        catch (err) {
            console.log("Error:", err)
        }
    }

    const [usersLogs, setusersLogs] = useState([])
    const getUserLogsData = async () => {
        try {
            const response = await fetch(`${Url}/sports/getAllUsersSports/${localStorage.getItem("adminToken")}`, {
                method: 'GET',
                headers: {
                    Accept: "application/json",
                },

            })
            const json = await response.json()
            console.log(json)
            if (json.Status === true) {
                setusersLogs(json.Data)
            }
            else {
                setusersLogs([])
            }
        }
        catch (err) {
            console.log("Error:", err)
        }
    }

    const [Allbookings, setAllbookings] = useState([])
    const getAllBookingsData = async () => {
        try {
            const response = await fetch(`${Url}/sports/getAllBooking/${localStorage.getItem("adminToken")}`, {
                method: 'GET',
                headers: {
                    Accept: "application/json",
                },

            })
            const json = await response.json()
            console.log(json)
            if (json.Status === true) {
                setAllbookings(json.data)
            }
            else {
                setAllbookings([])
            }
        }
        catch (err) {
            console.log("Error:", err)
        }
    }
    const SetRangeValue = async (obj) => {
        try {
            let token = localStorage.getItem("adminToken")
            const url = `${Url}/sports/bulkUpdatePrice/${token}`;
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj),
            });
            const resp = await response.json();
            console.log(resp);
            if (resp.Status === true) {
                return true;
            }
            return false;
        } catch {
            return false;
        }
    };
    const [Sports, setSports] = useState([])
    const getAllSportssData = async () => {
        try {
            const response = await fetch(`${Url}/sports/getSportsDashboard/${localStorage.getItem("adminToken")}`, {
                method: 'GET',
                headers: {
                    Accept: "application/json",
                },

            })
            const json = await response.json()
            console.log(json)
            if (json.Status === true) {
                setSports(json.data)
            }
            else {
                setSports([])
            }
        }
        catch (err) {
            console.log("Error:", err)
        }
    }



    const fetchLoneStarThisMonthBookings = async () => {
        try {
            const response = await fetch(
                `${Url}/sports/getAllBookingMonth/${localStorage.getItem("adminToken")}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json, text/plain, /",
                        "Content-Type": "application/json",
                    },
                }
            );

            const json = await response.json();
            // console.log(json)
            if (json.Status) {
                setAllbookings(json.data);
            } else {
                setAllbookings([]);
            }
        } catch {
            // alert("Some Problem update token");
        }
    };

    const fetchDateRangeBookings = async (fromdate, todate) => {
        try {
            const response = await fetch(
                `${Url}/sports/getAllBookingDateRange/${localStorage.getItem("adminToken")}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json, text/plain, /",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        fromDate: fromdate,
                        toDate: todate,
                    }),
                }
            );

            const json = await response.json();
            // console.log(json)
            if (json.Status) {
                setAllbookings(json.data);
            } else {
                setAllbookings([]);
            }
        } catch {
            // alert("Some Problem update token");
        }
    };

    const fetchLoneStarBookingIdBookings = async (bookingId) => {
        try {
            const response = await fetch(
                `${Url}/sports/getParticularBooking/${localStorage.getItem("adminToken")}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json, text/plain, /",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        sportBookingId: bookingId,
                    }),
                }
            );

            const json = await response.json();
            console.log(json)
            if (json.Status) {
                setAllbookings([json.data]);
            } else {
                setAllbookings([]);
            }
        } catch {
            // alert("Some Problem update token");
        }
    };

    const fetchLoneStarBookingPaymentstatusBookings = async (status) => {
        try {
            if (status == 1) {
                var statusname = "ADVANCED";
            } else {
                var statusname = "SUCCESS";
            }

            const response = await fetch(
                `${Url}/sports/getAllBookingPaymentStatus/${localStorage.getItem(
                    "adminToken"
                )}/${statusname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json, text/plain, /",
                        "Content-Type": "application/json",
                    },
                }
            );

            const json = await response.json();
            // console.log(json)
            if (json.Status) {
                setAllbookings(json.data);
            } else {
                setAllbookings([]);
            }
        } catch {
            // alert("Some Problem update token");
        }
    };

    const [nextDate,setnextDate] = useState()
    const [prevDate,setprevDate] = useState()
    const [Apiresponse, setapiresponse] = useState([]);

    
    const fetchAllSlotsPriceMgmt = async (today, yesterday, tommorow) => {

        try {
        const response = await fetch(
            `${Url}/sports/getEightPrevAfter/${localStorage.getItem(
            "adminToken"
            )}`,
            {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, /",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                date: today,
            }),
            }
        );

        const json = await response.json();
        console.log(json);
        if (json.Status) {
            setapiresponse(json.data);
            setnextDate(tommorow);
            setprevDate(yesterday);
        } else {
        }
        } catch {
        // alert("Some Problem update token");
        }
    };

    function getDateRange(onedate) {
        const currentDate = new Date(onedate);
    
        // Get provided date
        const providedYear = currentDate.getFullYear();
        const providedMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
        const providedDay = String(currentDate.getDate()).padStart(2, "0");
        const providedDate = `${providedYear}-${providedMonth}-${providedDay}`;
    
        // Get previous date
        const previousDate = new Date(currentDate);
        previousDate.setDate(previousDate.getDate() - 1);
        const previousYear = previousDate.getFullYear();
        const previousMonth = String(previousDate.getMonth() + 1).padStart(2, "0");
        const previousDay = String(previousDate.getDate()).padStart(2, "0");
        const previous = `${previousYear}-${previousMonth}-${previousDay}`;
    
        // Get next date
        const nextDate = new Date(currentDate);
        nextDate.setDate(nextDate.getDate() + 1);
        const nextYear = nextDate.getFullYear();
        const nextMonth = String(nextDate.getMonth() + 1).padStart(2, "0");
        const nextDay = String(nextDate.getDate()).padStart(2, "0");
        const next = `${nextYear}-${nextMonth}-${nextDay}`;
    
        return {
          providedDate: providedDate,
          previousDate: previous,
          nextDate: next,
        };
      }
    const fetchAllSlotsPriceMgmtforDifferentDates = async (date) => {
    let details = getDateRange(date);
    try {
        const response = await fetch(
        `${Url}/sports/getEightPrevAfter/${localStorage.getItem(
            "adminToken"
        )}`,
        {
            method: "POST",
            headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
            },
            body: JSON.stringify({
            date: details.providedDate,
            }),
        }
        );

        const json = await response.json();
        console.log(json);
        if (json.Status) {
            setapiresponse(json.data);
            setnextDate(details.nextDate);
            setprevDate(details.previousDate);
        } else {
        }
    } catch {
        // alert("Some Problem update token");
    }
    };





    return (
        <AuthContext.Provider value={{

            auth, setAuth,
            date, setDate,
            sportsData, setSportsData,
            selectedSportId, setSelectedSportId,
            selectedSlottId, setSelectedSlotId,
            price, setPrice,
            turfName, setTurfName,
            counter, setCounter, Url, isAuth,
            setisAuth, selectedSlotName, setselectedSlotName,
            selectedSlotStart, setselectedSlotStart,
            selectedSlotEnd, setselectedSlotEnd,
            AuthFlowOtp, setAuthFlowOtp, Profile, selectedSportName, setselectedSportName, SlotsCounterData,
            setSlotsCounterData,
            isOpen, setIsOpen, loogedinAdmin, setloogedinAdmin, addUserPopUp, setAddUserPopUp,
            isDashboard, setIsDashboard, usersMgmt,
            setusersMgmt, getUserManagementData, getUserLogsData, usersLogs, setusersLogs,
            Allbookings, setAllbookings, getAllBookingsData,
            Sports, setSports, getAllSportssData, SetRangeValue,
            fetchLoneStarThisMonthBookings,
            fetchDateRangeBookings,
            fetchLoneStarBookingIdBookings, fetchLoneStarBookingPaymentstatusBookings,
            bookingPopUp, setBookingPopUp,websiteData,fetchAllSlotsPriceMgmt,

            nextDate,setnextDate,Apiresponse,setapiresponse,
            prevDate,setprevDate,fetchAllSlotsPriceMgmtforDifferentDates,subtotalDict,
            setsubtotalDict
        }}>
            {children}
        </AuthContext.Provider>
    )
}


export { AuthContext, AuthProvider };
