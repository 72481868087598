import React from 'react'

const SportsCard = ({ data }) => {
    return (
        <div className="flex flex-col">
            <div className='overflow-hidden rounded-lg'>
                <img
                    loading="lazy"
                    src={data.image}
                    className="w-full aspect-[0.81] object-cover"
                />
            </div>
            <div className="mt-3 w-full text-2xl font-bold leading-9 text-[#AF5800]">
                {data.heading}
            </div>
            <div className="mt-2 w-full text-lg leading-7 text-justify text-zinc-800">
                {data.para}
            </div>
        </div>
    )
}

export default SportsCard