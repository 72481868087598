import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthContext } from './Context/AuthProvider';
import DynamicPricing from './Pages/DynamicPricings';
import Home from './Pages/Home';
import OurBookings from './Pages/OurBookings';
import SignupUsers from './Pages/SignupUsers';
import SlotsMgmt from './Pages/SlotsMgmt';
import Sports from './Pages/Sports';
import UsersMgmt from './Pages/UserMgmt';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import About from './Pages/About';
import Privacy from './Pages/Privacy';
import Cancellation from './Pages/Cancellation';
import Terms from './Pages/Terms';
import HomePage from './Pages/HomePage';
import Contact from './Pages/Contact';
import Footer1 from './components/Footer1';
import { Link } from 'react-router-dom';

import { FaPhone, FaWhatsapp } from "react-icons/fa";
function App() {
  const { isDashboard } = useContext(AuthContext)



  return (
    <>
      <Navbar />

      <Routes>

        <Route path='/book' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<About />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/cancellation' element={<Cancellation />} />
        <Route path='/terms' element={<Terms />} />
        {isDashboard ? <Route path='/sports' element={<Sports />} /> : <Route path='/sports' element={<Home />} />}
        {isDashboard ? <Route path='/slots-management' element={<SlotsMgmt />} /> : <Route path='/slots-management' element={<Home />} />}
        {isDashboard ? <Route path='/our-pricings' element={<DynamicPricing />} /> : <Route path='/our-pricings' element={<Home />} />}
        {isDashboard ? <Route path='/our-bookings' element={<OurBookings />} /> : <Route path='/our-bookings' element={<Home />} />}
        {isDashboard ? <Route path='/users' element={<SignupUsers />} /> : <Route path='/users' element={<Home />} />}
        {isDashboard ? <Route path='/user-management' element={<UsersMgmt />} /> : <Route path='/user-management' element={<Home />} />}
      </Routes>

      <Footer1 />



      <Link
        Link
        to={`tel:+917006100174`}
        target="_blank"
        className=" whatsapp-button inline-block fixed bottom-[20px] left-[20px] w-[50px] h-[50px] bg-[#5092FB] p-[12px]  rounded-full text-white z-10 text-[25px] overflow-hidden"
      >
        <span className="sr-only">what's app</span>{" "}
        <FaPhone className="transform rotate-[100deg]" />
      </Link>

      {/* Whats app******** */}
      <Link
        Link
        to={`https://wa.me/7006100174`}
        target="_blank"
        className="whatsapp-button inline-block fixed bottom-[20px] right-[20px] w-[50px] h-[50px] bg-[#4DC251] p-[12px]  rounded-full text-white z-10 text-[25px] overflow-hidden"
      >
        <span className="sr-only">what's app</span> <FaWhatsapp />
      </Link>
    </>
  );
}

export default App;
