import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../Context/AuthProvider'
import "../style/UserManagement.css"

function SignupUsers() {

  const {usersLogs,getUserLogsData} = useContext(AuthContext)

  useEffect(()=>{
    getUserLogsData()
  },[])


 

  return (
    <div className="lg:max-w-[1280px] max-w-full mx-auto usermanagement mt-5 ">
      <table className="usermanagementtable">
        <thead>
          <tr className="tablerow">
            {["Name", "Phone", "Email", "LoggedIn"].map((headerLabel) => (
              <th key={headerLabel}>{headerLabel}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {usersLogs.map((user) => (
            <tr>
              <td>{user.Name}</td>
              <td>{user.phoneNumber}</td>
              <td>{user.Email}</td>
              {/* <td className='d-flex flex-column'>
              {Object.entries(user.accessScope).map(([key, value]) => (
                value && <span className='badge bg-success m-1 w-auto' key={key}>{key}</span>
              ))}
            </td> */}
              <td>{user.isAuth? "Yes" : "No"}</td>
              {/* <td> */}
              {/* {isAdmin ? <button className='btn me-2' onClick={() => { editUserData(user) }}>edit</button> : ""} */}
              {/* {userAccess.isAdmin ? user.isAdmin ? (
                ""
              ) : (
                <button
                  className="btn"
                  onClick={() => {
                    deleteUserFromManagement(user.Email);
                  }}
                >
                  delete
                </button>
              ) : ""} */}
              {/* </td> */}
            </tr>
          ))}
        </tbody>
      </table>

      {/* {popup ? (
      <AddUserPopUP setPopup={setPopup} GetAllUsers={GetAllUsers} />
    ) : (
      ""
    )} */}
      {/* {isEditpPop ? <EditUserMagPopup /> : ""} */}
    </div>
  )
}

export default SignupUsers