import React, { useContext, useState } from 'react';
import { AuthContext } from '../Context/AuthProvider';

const BookingFilter = (props) => {
    const { sendFilterBookingIdRequest, sendFilterBookingPaymentStatusRequest, sendFilterDatesRequest, EngineUrl, setBookingData,
        fetchLoneStarThisMonthBookings, getAllBookingsData, fetchDateRangeBookings, fetchLoneStarBookingIdBookings,
        fetchLoneStarBookingPaymentstatusBookings } = useContext(AuthContext)


    const { mheading, setAllBookings } = props;

    const [selectedValue, setSelectedValue] = useState('div0');
    const [selectedNumber, setselectedNumber] = useState('0')
    const [bookingId, setBookingId] = useState('');
    const [checkinDate, setCheckinDate] = useState('None')
    const [checkoutDate, setCheckoutDate] = useState('None')









    const handleOptionChange = (event) => {
        setSelectedValue(event.target.value);

    };

    const handlePayStatusChange = (event) => {

        var type = event.target.value;
        setselectedNumber(event.target.value);
        fetchLoneStarBookingPaymentstatusBookings(type)


    };
    return (
        <div className='reservationdesktable max-md:px-5'>
            <h3>{mheading}</h3>
            <div className="b-filters">
                {/* <h5>Filters</h5> */}
                {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3 flex flex-wrap gap-10 max-md:gap-5">
                        <label htmlFor='' className='flex items-center gap-1'>
                            <input
                                type={type}
                                checked={selectedValue === 'div0'}
                                value="div0"
                                onClick={(e) => { handleOptionChange(e); getAllBookingsData();setselectedNumber("0") }}
                                id={`inline-${type}-2`}
                            />
                            All

                        </label>
                        <label htmlFor='' className='flex items-center gap-1'>
                            <input
                                type={type}
                                checked={selectedValue === 'div01'}
                                value="div01"
                                onClick={(e) => { handleOptionChange(e); fetchLoneStarThisMonthBookings() }}
                                id={`inline-${type}-2`}
                            />
                            This Month

                        </label>
                        <label htmlFor='' className='flex items-center gap-1'>
                            <input
                                type={type}
                                value="div1"
                                checked={selectedValue === 'div1'}
                                onChange={handleOptionChange}
                                id={`inline-${type}-2`}
                            />
                            Date Range Bookings

                        </label>
                        <label htmlFor='' className='flex items-center gap-1'>
                            <input
                                type={type}
                                value="div2"
                                checked={selectedValue === 'div2'}
                                onChange={handleOptionChange}
                                id={`inline-${type}-2`}
                            />
                            Single Booking Search

                        </label>
                        
                        <button className='self-center btn  py-[.3rem] px-[1rem]' onClick={() => { setSelectedValue("") }}>close</button>
                    </div>
                ))}
            </div>
            <div className="filter">
                <div id="div0" style={{ display: selectedValue === 'div0' ? 'block' : 'none' }}>
                {['radio'].map((type) => (
                        <div key={`inline-${type}`} className="mb-3 flex gap-5 items-center">
                            <label htmlFor={`inline-${type}-2`} className='flex items-center gap-1'>
                                <input
                                    type="checkbox"
                                    name="group1"
                                    value="1"
                                    checked={selectedNumber === '1'}
                                    onChange={handlePayStatusChange}
                                    id={`inline-${type}-2`}
                                />
                                ADVANCED

                            </label>
                            <label htmlFor={`inline-${type}-3`} className='flex items-center gap-1'>
                                <input
                                    type="checkbox"
                                    name="group1"
                                    value="2"
                                    checked={selectedNumber === '2'}
                                    onChange={handlePayStatusChange}
                                    id={`inline-${type}-3`}
                                />
                                SUCCESS

                            </label>
                        </div>

                    ))}
                </div>
                <div id="div01" style={{ display: selectedValue === 'div01' ? 'block' : 'none' }}>
                </div>

                <div id="div1" style={{ display: selectedValue === 'div1' ? 'block' : 'none' }}>

                    <div className="date-fltr ">
                        <div className="dt-flt-inr grid grid-cols-3 max-md:grid-cols-1 max-md:gap-4 mt-5">
                            <div className="flex gap-5 max-md:gap-1 items-center max-md:justify-between flex-wrap">
                                <label htmlFor="/">Playing date from:</label>
                                <input className='dates border-2 rounded-sm  py-[.3rem] px-[1rem]' type="date" name="" id="checkin" value={checkinDate} onChange={(e) => { setCheckinDate(e.target.value) }} />
                            </div>
                            <div className="flex gap-5 items-center max-md:justify-between max-md:gap-1 flex-wrap">
                                <label htmlFor="/">Playing date to:</label>
                                <input className='dates border-2 rounded-sm  py-[.3rem] px-[1rem]' type="date" name="" id="checkout" value={checkoutDate} onChange={(e) => { setCheckoutDate(e.target.value) }} />
                            </div>
                            <div className='flex items-center'>
                                <button className="btn py-[.3rem] px-[1rem] w-[30%] max-md:w-[auto]" onClick={() => { fetchDateRangeBookings(checkinDate, checkoutDate) }}>Show Bookings</button>

                            </div>

                        </div>

                    </div>
                </div>


                <div className="flex items-center" id='div2' style={{ display: selectedValue === 'div2' ? 'block' : 'none' }}>
                    <div className='flex flex-wrap items-center gap-5'>
                        <span>Booking id:</span>
                        <input className=' border-2 rounded-sm py-[.3rem] px-[1rem] ' type="text" id="BookingId" value={bookingId} onChange={(e) => { setBookingId(e.target.value) }} placeholder='Enter Booking Id' />

                    </div>
                    <div className=" mt-3">
                        <button className="btn py-[.3rem] px-[1rem]" onClick={() => { fetchLoneStarBookingIdBookings(bookingId) }}>Show Bookings</button>
                    </div>
                </div>

                


            </div>
        </div>
    )
}

export default BookingFilter