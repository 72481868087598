import React from 'react'

const PritermCon = ({ data }) => {
    return (
        <div className="flex flex-col max-w-[1280px] mx-auto mt-10 max-md:px-5">
            <div className="w-full text-[24px] font-bold leading-9 text-amber-700 max-md:max-w-full">
                {data.heading}
            </div>
            <div className="mt-3 w-full text-lg leading-7 text-justify text-zinc-800 max-md:max-w-full">
                {data.para}
            </div>
            {data.para2 && <div className="mt-3 w-full text-lg leading-7 text-justify text-zinc-800 max-md:max-w-full">
                {data.para2}
            </div>}
        </div>
    )
}

export default PritermCon