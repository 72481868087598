import React from 'react'
import { Link } from 'react-router-dom'

const Form = () => {
    return (
        <div>
            <div className="max-w-[1280px] mx-auto flex gap-5 max-md:flex-col max-md:gap-0 max-md:px-5 mt-5 md:mt-10">
                <div className="flex flex-col w-[63%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col text-lg leading-6 max-md:mt-10 max-md:max-w-full">
                        <div className="text-2xl font-bold leading-9 text-amber-700 max-md:max-w-full">
                            Contact <span className="text-amber-700">us</span>
                        </div>
                        <div className="mt-4 leading-7 text-justify text-zinc-800 max-md:max-w-full">
                            Connecting Passion with Play: Reach Out to Us!
                        </div>
                        <div className="mt-6 text-2xl font-bold leading-9 text-amber-700 max-md:max-w-full">
                            Contact <span className="text-amber-700">details</span>
                        </div>
                        <div className="flex gap-3 self-start mt-4">
                            <div className="text-neutral-600">Phone:</div>
                            <Link to="to:+917006100174" className=" text-zinc-800">+91 7006100174</Link >
                        </div>
                        <div className="flex gap-3 self-start mt-3 whitespace-nowrap">
                            <div className="text-neutral-600">Email:</div>
                            <Link to="mailto:lonestar_i@hotmail.com" className="text-zinc-800">lonestar_i@hotmail.com</Link>
                        </div>
                        <div className="flex gap-3 mt-3 max-md:flex-wrap">
                            <div className="text-neutral-600">Address:</div>
                            <Link to="https://www.google.com/maps/place/LoneStar+Kashmir+FC/@34.0449992,74.799122,17z/data=!3m1!4b1!4m6!3m5!1s0x38e18f8a5e41a7fb:0xe77c381785ec26cb!8m2!3d34.0449992!4d74.8016969!16s%2Fg%2F11rbfdqqts?entry=ttu" target='_blank' className="text-neutral-700 max-md:max-w-full">
                                <span className="text-neutral-700">
                                    Lone Star Complex, Baghat Chowk,{" "}
                                </span>
                                <span className="text-neutral-700">190005 Srinagar Kashmir</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col ml-5 w-[37%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow justify-center text-lg leading-6 text-neutral-600 max-md:mt-10">
                        <div className="flex flex-col justify-center px-6 py-7 bg-indigo-50 rounded-xl  border-blue-400 border-solid max-md:px-5">
                            <div className="flex flex-col bg-indigo-50">
                                <div className="text-2xl font-bold leading-9 text-slate-900">
                                    Get In Touch!
                                </div>
                                <div className="flex flex-col justify-center items-start px-4 py-3.5 mt-6 w-full bg-white rounded-md max-md:pr-5">
                                    <div className="flex gap-3">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/558deff2f7f7c02d8987f8530f73852fec33effabbbd60d79931315f7ec7cb3e?"
                                            className="shrink-0 aspect-square w-[26px]"
                                        />
                                        <div>Your Name*</div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center items-start px-4 py-3.5 mt-4 w-full bg-white rounded-md max-md:pr-5">
                                    <div className="flex gap-3">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fff8c000cb0ddafe28175db12f79ebf6a804024ec925cdded9ed9b706bb15bc0?"
                                            className="shrink-0 aspect-square w-[26px]"
                                        />
                                        <div>Phone Number*</div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center items-start px-4 py-3.5 mt-4 w-full whitespace-nowrap bg-white rounded-md max-md:pr-5">
                                    <div className="flex gap-3">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b01ee9f4abb18d33f6d9ef3b8d88035a0f5cfc2c2fbfcb6e336fd3e30444385?"
                                            className="shrink-0 aspect-square w-[26px]"
                                        />
                                        <div>Email*</div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center items-start px-4 py-3.5 mt-4 w-full whitespace-nowrap bg-white rounded-md max-md:pr-5">
                                    <div className="flex gap-3">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d401025fca65798a7f35cc4b7c8d1a73192274a35e20e1303f758836484a6747?"
                                            className="shrink-0 self-start w-6 aspect-square"
                                        />
                                        <div>Message</div>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center px-16 py-3.5 mt-6 text-xl font-medium leading-7 text-white whitespace-nowrap bg-blue-400 rounded-md max-md:px-5">
                                    Submit
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Form