import React, { useContext, useState } from 'react'

import { GrClose } from 'react-icons/gr'
import { AuthContext } from '../Context/AuthProvider'
// import "../Style/AddUserPopUp.css"



const AddUserPopUP = ({ setPopup, data, setdata, editsportid }) => {


    const { Url, getUserManagementData, setSports, getAllSportssData } = useContext(AuthContext)
    const [startDate, setstartDate] = useState("")
    const [endDate, setendDate] = useState("")

    function countofslot(detail) {
        const slotsArray = Object.keys(detail);

        // Get the count of slots
        const slotCount = slotsArray.length;

        // Log the count of slots
        console.log("Number of slots:", slotCount);

        // Alternatively, you can return the count if you want to use it elsewhere
        return slotCount;
    }

    getAllSportssData()

    const DeleteSlot = (slot) => {
        // Confirm with the user
        const confirmDelete = window.confirm("Are you sure you want to delete this slot?");

        // If user confirms deletion
        if (confirmDelete) {
            // Create a copy of the current slots object
            const currentSlots = { ...data.slots };

            // Remove the specified slot from the copy
            delete currentSlots[slot];

            // Update the state without the removed slot
            setdata(prevState => ({
                ...prevState,
                slots: currentSlots
            }));
        } else {
            // If user cancels deletion, do nothing
            // You may choose to add some feedback or action here if needed
            console.log("Deletion cancelled by user");
        }
    }


    const Addslot = () => {
        if (startDate === "" || endDate === "") {
            alert("Please select values")
        }
        else {
            let count = countofslot(data.slots)
            let snumber = `slot${count + 1}`

            const currentSlots = { ...data.slots };
            currentSlots[snumber] = {
                "start": startDate,
                "end": endDate
            }

            setdata(prevState => ({
                ...prevState,
                slots: currentSlots
            }));
            setstartDate("")
            setendDate("")
        }

    }

    const handleInputChange = (event) => {
        // Update the sportName in state with the new value from the input field
        setdata(prevState => ({
            ...prevState,
            sportName: event.target.value
        }));
    };

    const UpdateSport = async () => {

        try {
            const resp = await fetch(
                `${Url}/sports/updateSportsDashboard/${localStorage.getItem("adminToken")}/${editsportid}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data), // Serialize the data as JSON
                }
            );
            const json = await resp.json();
            console.log(json)
            // Handle response as needed
            getAllSportssData()
            setPopup(false)
        } catch (err) {
            console.error("Error adding WATI credentials:", err);
            // Handle error as needed
        }

    }

    function formatTime(timeString) {
        const [hours, minutes] = timeString.split(':');
        let formattedHours = parseInt(hours, 10);
        const ampm = formattedHours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        formattedHours = formattedHours % 12 || 12;

        return `${formattedHours}:${minutes} ${ampm}`;
    }


    return (
        <div className='Addpopup'>


            <div className='userform  mb-5 bg-white rounded'>
                <div className='heading flex justify-between'>
                    <h3>Edit Sport</h3>
                    <GrClose size={25} style={{ color: '#fff' }} onClick={() => { setPopup(false) }} className='closebooking' />


                </div>

                <div class="grid grid-cols-2 max-md:grid-cols-1 mt-4 gap-10 max-md:gap-5 ">
                    <img src={data["images"][0]} class="card-img-top" alt="..." />
                    <div className=' w-100'>

                        <div className='flex flex-wrap items-center gap-10 max-md:gap-0 max-md:justify-between max-md:px-5 max-md:w-full'>
                            <h1>Sports Name:</h1>
                            <input type='text' className='fromdata border-2 px-4 py-[.3rem]' value={data.sportName} onChange={(e) => { handleInputChange(e) }} placeholder='Sport Name' required />
                        </div>
                        <div className='mt-2 '>
                            <h6 className='heading'>Slots</h6>
                            <div className='grid grid-cols-2 gap-2 border-3 mt-2 max-md:px-5 '>
                                {Object.entries(data.slots)
                                    .sort(([, slotA], [, slotB]) => slotA.start - slotB.start)
                                    .map(([key, value]) => (
                                        <div className='flex justify-center background px-[10px] py-[10px] rounded-md' key={key}>
                                            <span className='max-md:!text-[13px] !text-[18px]' onClick={() => { DeleteSlot(key) }}>
                                                {formatTime(value.start)} - {formatTime(value.end)}
                                            </span>
                                        </div>
                                    ))}
                            </div>
                            <div className='grid grid-cols-3 gap-5 max-md:grid-cols-1 mt-5 max-md:px-5 '>
                                <div className=' w-full '>
                                    <input className=' w-full border-2 rounded-sm px-4 py-[.3rem]' type='time' value={startDate} onChange={(e) => { setstartDate(e.target.value) }} />
                                </div>
                                <div className='w-full '>
                                    <input className=' w-full border-2 rounded-sm px-4 py-[.3rem]' type='time' value={endDate} onChange={(e) => { setendDate(e.target.value) }} />
                                </div>
                                <div className='border-3'>
                                    <button className='btn py-[.5rem] px-[1rem] w-full' onClick={() => { Addslot() }}>Add slot</button>
                                </div>
                            </div>

                        </div>
                        <div className='mt-3'>
                            <h6 className='heading'>Turf</h6>
                            <div className="grid grid-cols-2 gap-2 border-3 mt-2 max-md:px-5 " style={{ display: "1px solid green" }}>
                                {data.turfs.map((turf) => (
                                    <div className='flex justify-center background px-[10px] py-[10px] rounded-md'>
                                        <span className="max-md:!text-[13px] !text-[18px]" >{turf.name}</span>
                                    </div>
                                ))}


                            </div>

                        </div>

                        <div className=''>

                            {/* <div className='flex justify-end max-md:px-5 mt-5'>
                                <button className='btn w-full py-[16px] px-[1rem]' onClick={() => { UpdateSport() }} >Update</button>

                            </div> */}
                        </div>
                    </div>


                </div>





            </div>
        </div>
    )
}

export default AddUserPopUP