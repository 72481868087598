import html2pdf from 'html2pdf.js';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../assets/logo.svg";

function Success({ successBookingDetails, selectedSportName }) {
  function formatDate(dateString) {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const [year, monthIndex, day] = dateString.split('-');
    const month = months[parseInt(monthIndex, 10) - 1]; // Adjust index to start from 0

    return `${parseInt(day, 10)} ${month}, ${year}`;
  }

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    let formattedHours = parseInt(hours, 10);
    const ampm = formattedHours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    formattedHours = formattedHours % 12 || 12;

    return `${formattedHours}:${minutes} ${ampm}`;
  }

  const [isPDFGenerationInProgress, setPDFGenerationInProgress] = useState(false);

  // Function to handle the download button click event
  const handleDownloadClick = () => {
    if (!isPDFGenerationInProgress) {
      setPDFGenerationInProgress(true);
      const element = document.getElementById('success-content');
      html2pdf()
        .from(element)
        .save('receipt.pdf')
        .then(() => setPDFGenerationInProgress(false))
        .catch(error => {
          console.error('Error generating PDF:', error);
          setPDFGenerationInProgress(false);
        });
    }
  };
  return (
    <div id="success-content" className="flex justify-center items-center py-10 max-md:py-5 nav_background max-md:px-5">
      <div className="w-full max-w-[1024px] max-md:max-w-full">
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-10 max-md:gap-0">
          <div className="flex flex-col pl-10 max-md:pl-0 self-stretch my-auto max-md:mt-10 max-md:max-w-full">
            <img
              loading="lazy"
              src={Logo}
              className="max-w-full aspect-[1.14] w-[113px]"
            />
            <div className="mt-6 heading !px-0 !bg-transparent max-md:max-w-full !text-[#333333]">
              Booking Confirmed Successfully!
            </div>
            <div className="mt-6 mr-7 text max-md:mr-2.5 max-md:max-w-full">
              Thank you for booking with us. Your reservation is confirmed. If
              there’s anything you need before arrival, please don’t hesitate
              to reach out to us.{" "}
            </div>
            <div className="flex gap-5  mt-6 max-md:flex-wrap">

              <button className="flex justify-center items-center text px-10 py-4 btn rounded-[44px]" style={{ border: "1px solid #5092FB", backgroundColor: "#5092FB", color: "white" }} onClick={handleDownloadClick}>
                {isPDFGenerationInProgress ? 'Generating PDF...' : 'Download'}
              </button>

              <Link className="my-auto text underline text-zinc-800" onClick={() => { window.location.reload() }}>
                Go Back to Home
              </Link>
            </div>
          </div>
          <div className="flex flex-col grow justify-center max-md:mt-5 max-md:max-w-full">
            <div className="flex flex-col px-10 py-12 bg-white rounded-3xl max-md:px-5 max-md:max-w-full" >
              <div className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full">
                <div className="flex flex-col">
                  <div className="heading !px-0 !bg-transparent !text-[#333333]">
                    ₹ {successBookingDetails.price.amountPaid}
                  </div>
                  <div className="mt-1 text-sm font-medium leading-5 text-center text-stone-300" style={{ color: "#5092FB" }}>
                    {successBookingDetails.price.amountPaid === successBookingDetails.price.totalAmount ? "Payment success!" : "Advance payment done!"}
                  </div>
                </div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b1bde0ee4479f5a93a7d6374b45df9f10b9836e9bf9d7824d0035f6c8399c95?"
                  className="shrink-0 aspect-square w-[52px]"
                />
              </div>
              <div className="shrink-0 mt-3 divider !h-0" />
              <div className="mt-4 heading !px-0 !bg-transparent max-md:max-w-full !text-[#333333]">
                Guest Details
              </div>
              <div className="flex gap-5 justify-between mt-4  max-md:flex-wrap max-md:max-w-full">
                <p className="text">Booking Id</p>
                <p className="text">{successBookingDetails.sportBookingId}</p>
              </div>
              <div className="flex gap-5 justify-between mt-4  max-md:flex-wrap max-md:max-w-full">
                <p className="text">Guest Name</p>
                <p className="text">{successBookingDetails.guestInfo.Name}</p>
              </div>
              <div className="flex gap-5 justify-between mt-2  max-md:flex-wrap max-md:max-w-full">
                <p className="text">Phone Number</p>
                <p className="text">{successBookingDetails.guestInfo.Phone}</p>
              </div>
              <div className="flex gap-5 justify-between mt-2  max-md:flex-wrap max-md:max-w-full">
                <p className="text">Email</p>
                <p className="text break-words">
                  {successBookingDetails.guestInfo.Email}
                </p>
              </div>
              <div className="shrink-0 mt-4 border-[1.1px] !border-dashed border-[#333333]" />
              <div className="mt-4 heading !px-0 !bg-transparent max-md:max-w-full !text-[#333333]">
                Booking Details
              </div>
              <div className="flex gap-5 justify-between mt-4  max-md:flex-wrap max-md:max-w-full">
                <p className="text">Sports</p>
                <p className="text">{selectedSportName}</p>
              </div>
              <div className="flex gap-5 justify-between mt-2 max-md:flex-wrap max-md:max-w-full">
                <p className="text">Playing Date</p>
                <p className="text">
                  {formatDate(successBookingDetails.bookingDate)}
                </p>
              </div>
              <div className="flex gap-5 justify-between mt-2 max-md:flex-wrap max-md:max-w-full">
                <div className="text">Slot</div>
                <div className="text">
                  {formatTime(successBookingDetails.bookedSlots[0].slotstart)} - {formatTime(successBookingDetails.bookedSlots[successBookingDetails.bookedSlots.length - 1].slotEnd)}
                </div>
              </div>
              <div className="flex gap-5 justify-between mt-2 max-md:flex-wrap max-md:max-w-full">
                <div className="text">Turf</div>
                <div className="text">
                  {successBookingDetails.bookedSlots[0].turfName}
                </div>
              </div>
              <div className="shrink-0 mt-4 border-[1.1px] !border-dashed border-[#333333]" />
              <div className="mt-4 heading !px-0 !bg-transparent max-md:max-w-full !text-[#333333]">
                Payment Details
              </div>
              <div className="flex gap-5 justify-between mt-4 text-lg leading-5 max-md:flex-wrap max-md:max-w-full">
                <p className="text">Booking Fee</p>
                <p className="text">₹ {successBookingDetails.price.amount}</p>
              </div>
              {successBookingDetails.price.amountPaid === successBookingDetails.price.totalAmount ? "" : <div className="flex gap-5 justify-between mt-4  max-md:flex-wrap max-md:max-w-full">
                <p className="text">Remaining Amount</p>
                <p className="text" style={{ color: "#5092FB" }}>₹ {Number(successBookingDetails.price.totalAmount) - Number(successBookingDetails.price.amountPaid)}</p>
              </div>}
              <div className="flex gap-5 justify-between mt-4  max-md:flex-wrap max-md:max-w-full">
                <p className="text">Tax</p>
                <p className="text">₹ {successBookingDetails.price.tax}</p>
              </div>
              <div className="flex gap-5 justify-between mt-2 max-md:flex-wrap max-md:max-w-full">
                <p className="text !bg-transparent">Total Fee</p>
                <p className="text !bg-transparent">₹ {successBookingDetails.price.totalAmount}</p>
              </div>
              <div className="flex gap-5 justify-between mt-2 max-md:flex-wrap max-md:max-w-full">
                <p className="text !bg-transparent">Transaction Id</p>
                <p className="text !bg-transparent">
                  {successBookingDetails.payment.payId}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Success