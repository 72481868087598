import React from 'react';
import AboutBann from "../assets/compressed/DSC05461_4_11zon.jpg";
// import AboutBann from "../assets/IMG_5330.jpg"
import { Helmet } from "react-helmet";
import AboutImg from "../assets/IMG_5447.jpg";
import ComAboutCard from '../components/ComAboutCard';
import ComBanner from '../components/ComBanner';



const About = () => {

    const aboutImage = [AboutBann]
    return (
        
        <div className=''>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover LoneStar Kashmir FC: Promoting Sporting Excellence in Kashmir</title>
                <meta name="description" content="Learn about LoneStar Kashmir FC, the visionary force behind community sporting dreams in Jammu & Kashmir."/>
            </Helmet>

            <ComBanner
                bannerImage={aboutImage}
                heading={"About Us"}
                subHeading={""}
                button={true}

            />
            <div className='mt-10'>
                <ComAboutCard
                    aboutImg={AboutImg}
                    heading={"About Us"}
                    paragraph={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}
                />
            </div>
        </div>
    )
}

export default About