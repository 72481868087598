import React, { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { IoMdAdd } from "react-icons/io";
import { RiSubtractFill } from "react-icons/ri";
import useRazorpay from "react-razorpay";
import { AuthContext } from '../Context/AuthProvider';
import BookingFilter from "../components/SlotPopup";





const SlotsMgmt = () => {

    const {
        date, setDate, isAuth, Url,
        sportsData, setSportsData,
        selectedSportId, setSelectedSportId,
        selectedSlottId, setSelectedSlotId,
        price, setPrice,
        turfName, setTurfName,
        counter, setCounter,
        selectedSlotName, setselectedSlotName,
        selectedSlotStart, setselectedSlotStart,
        selectedSlotEnd, setselectedSlotEnd,
        AuthFlowOtp, setAuthFlowOtp, Profile, selectedSportName, setselectedSportName, SlotsCounterData,websiteData,
        setSlotsCounterData
    } = useContext(AuthContext);

    const minDate = new Date();
    const maxDate = new Date(minDate.getFullYear(), 11, 31);

    const [TotalPrice, setTotalPrice] = useState(0)
    const [OrderId, setOrderId] = useState("None")
    const [Razorpay, createOrder] = useRazorpay();
    const [bookingDone, setbookingDone] = useState(false)
    const [successBookingDetails, setsuccessBookingDetails] = useState("None");
    const [availableSlot, setAvailableSlot] = useState(null);
    const [maxValueCounter, setmaxValueCounter] = useState(1)
    const [openInitialPopup, setopenInitialPopup] = useState(false)

    const [unblock,setunblock] = useState(false)
    const [footballturf,setfootballturf] = useState([{
        "name": "5 v 5  Turf A",
        "price": 1000
    },
    {
        "name": "5 v 5 Turf B",
        "price": 1000
    },
    {
        "name": "7 v 7 Turf C",
        "price": 2100
    }])

    const [cricketturf,setcricketturf] = useState([
    {
        "name": "7 v 7 Turf C",
        "price": 2100
    }])




    function getMaxValue(obj) {
        const values = Object.values(obj);
        let maxv = Math.min(...values);
        if (maxv === 0) {
            return 1
        }
        return Math.min(...values);
    }

    const getCounterMaxProbablity = async (sportId, date) => {
        try {
            const response = await fetch(`${Url}/sports/countervalue`, {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, /",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "seid": "779f43ea-5ed0-43ee-86fb-c7d296a769cf",
                    "sportId": sportId,
                    "date": date
                }),
            });

            const json = await response.json();
            console.log(json)
            if (json.Status === true) {
                setSlotsCounterData(json.Data)

            } else {
                console.log("Problem")
            }
        }
        catch {
            console.log("Problem")
        }
    };

    const handleSportClick = (sportId, sportname) => {
        setSelectedSportId(sportId);
        setselectedSportName(sportname)
        setselectedSlotName(null)
        setselectedSlotStart()
        setselectedSlotEnd()
        setSelectedSlotId(null)
        getCounterMaxProbablity(sportId, date)
    };

    const handleSlotClick = (name, start, end) => {
        setselectedSlotName(name)
        setselectedSlotStart(start)
        setselectedSlotEnd(end)
        setSelectedSlotId(`${start}-${end}`);
        setmaxValueCounter(getMaxValue(SlotsCounterData[name]))
        setPrice(0)
        setTurfName(null)
        setPrice(0)
        if (counter > 1) {
            setCounter(getMaxValue(SlotsCounterData[name]))
        }

    }
    const handleTurfClick = (trufPrice, turfname) => {
        setPrice(trufPrice);
        setTurfName(turfname);
        setmaxValueCounter(SlotsCounterData[selectedSlotName][turfname])
    }

    const handleCalendarChange = (newValue) => {

        const currentDate = newValue.toLocaleDateString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/\//g, '-');
        setDate(currentDate);
        getCounterMaxProbablity(selectedSportId, currentDate)
    }

    const handleChangeButton = () => {
        setAuthFlowOtp(true)
    }

    const handleopenInitialPopup = () => {
        setopenInitialPopup(true)
    }

    const increaseCounter = () => {
        if (counter >= maxValueCounter) {
            return;
        }
        setCounter(counter + 1)
    }
    const decreaseCounter = () => {
        if (counter === 1) {
            return;
        }
        setCounter(counter - 1)
    }

    const calculateAvailableSlot = (slot) => {
        console.log()
    }

    function formatTime(timeString) {
        const [hours, minutes] = timeString.split(':');
        let formattedHours = parseInt(hours, 10);
        const ampm = formattedHours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        formattedHours = formattedHours % 12 || 12;

        return `${formattedHours}:${minutes} ${ampm}`;
    }
    const BlockSlotForDate = async () => {
      let data = {
          "date": date,
          "sportName": selectedSportName,
          "blockedSlot": [
              {
                  "slotName": selectedSlotName,
                  "slotstart": selectedSlotStart,
                  "slotEnd": selectedSlotEnd,
                  "turfName": [turfName],
                  "turfPrice": price
              }
          ],
          "duration": String(counter),
          "Token":localStorage.getItem("adminToken")
      }

      try {
          const resp = await fetch(
            `${Url}/sports/blockTurfs`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data), // Serialize the data as JSON
            }
          );
          const json = await resp.json();
          if (json.Status === true) {
            //   alert("Blocked")
              websiteData(date)
          }
          // Handle response as needed
        } catch (err) {
          console.error("Error adding WATI credentials:", err);
          // Handle error as needed
        }


    }

    const UnBlockSlotForDate = async () => {
        let data = {
            "date": date,
            "sportName": selectedSportName,
            "blockedSlot": [
                {
                    "slotName": selectedSlotName,
                    "slotstart": selectedSlotStart,
                    "slotEnd": selectedSlotEnd,
                    "turfName": [],
                    "turfPrice": price
                }
            ],
            "duration": String(counter),
            "Token":localStorage.getItem("adminToken")
        }
        
       
        try {
            const resp = await fetch(
              `${Url}/sports/blockTurfs`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data), // Serialize the data as JSON
              }
            );
            const json = await resp.json();
            if (json.Status === true) {
                // alert("Unblocked")
                websiteData(date)
            }
            // Handle response as needed
          } catch (err) {
            console.error("Error adding WATI credentials:", err);
            // Handle error as needed
          }
  
  
    }
    useEffect(() => {
        setAvailableSlot(sportsData[0]?.slotData?.length);
    }, [])






    return (
        <div className='mt-5 max-w-[1280px] mx-auto'>
            
            
            <div className="flex flex-col mx-auto w-full">
            <BookingFilter />
                <div className='lg:max-w-[1024px]  mx-auto grid grid-cols-2 max-md:grid-cols-1 gap-10 lg:gap-5'>
                    <div className='w-full'>
                        <div className="heading  max-md:px-5 mt-10 max-md:mt-0">
                            Select Playing Date
                        </div>
                        <div className='mt-5 max-md:mt-10 flex justify-center  max-md:px-5'>
                            <Calendar
                                className="!w-[100%] custom-calendar"
                                onChange={handleCalendarChange}
                                value={date}
                                width="100%"
                                minDate={minDate}
                                maxDate={maxDate}
                            />

                    </div>
                    </div>
                    <div>
                        <div className="heading mt-10 max-md:px-5">
                            Select Sport
                        </div>
                        <div className="grid grid-cols-2 gap-5 max-md:grid-cols-2  max-md:px-5 mt-5 max-md:mt-10 lg:h-[45%] max-md:h-100">

                            {sportsData.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative  flex flex-1 justify-center items-center rounded-3xl w-25 max-md:w-full overflow-hidden cursor-pointer ${selectedSportId === item.sportId ? 'sportborder' : 'sportborderBefore'
                                        }`}
                                    onClick={() => handleSportClick(item.sportId, item.sportName)}
                                >
                                    <img
                                        loading="lazy"
                                        src={item.images[0]}
                                        alt={item.sportName}
                                        className="object-cover h-full w-full  inset-0 size-full overflow-hidden"
                                    />
                                    <div className="absolute inset-0 bg-black opacity-50"></div>
                                    <p className=' !text-[16px] lg:!text-[18px] font-semibold !text-white absolute bottom-[15%] max-md:bottom-[10%] left-1/2 transform -translate-x-1/2 ' >
                                        {item.sportName}
                                    </p>


                                </div>
                            ))}

                        </div>


                    </div>

                    {/* Calendar */}

                   
                </div>

                <div className='grid grid-cols-2 max-md:grid-cols-1 gap-5 max-w-[1024px] lg:w-[1024px]  max-md:w-full lg:mx-auto'>
                    <div className=' flex flex-col '>
                        <div className="w-full  max-md:px-5 lg:mx-auto heading mt-10 max-md:mt-10">
                            Select Slot
                        </div>

                        <div className='w-full max-md:mt-10  max-md:px-5  lg:mx-auto'>
                            {selectedSportId === null ?
                                <div className='flex justify-center mt-5'>
                                    <div className='text'>Please Select Any Sport</div>
                                </div>
                                :
                                <div className='grid grid-cols-2 gap-5 mt-10 max-md:mt-0'>

                                    {sportsData.map((item, index) => (
                                        item?.sportId === selectedSportId && item.slotData ? (
                                            item.slotData
                                                // Sort slotData array based on slot start time
                                                .sort((a, b) => {
                                                    // Assuming slotStart is a string representing time in HH:MM format
                                                    const timeA = a.slotStart.split(':').map(Number);
                                                    const timeB = b.slotStart.split(':').map(Number);

                                                    if (timeA[0] !== timeB[0]) {
                                                        return timeA[0] - timeB[0]; // Sort by hour
                                                    } else {
                                                        return timeA[1] - timeB[1]; // Sort by minute if hours are equal
                                                    }
                                                })
                                                .map((slot, slotIndex) => (
                                                    slot.Turfs.length === 0 ? <div key={`${index}-${slotIndex}`} style={{ cursor: "pointer", opacity: "0.5", backgroundColor: "red", color: "white" }} className={` flex justify-center text background whitespace-nowrap rounded-[4px]`} onClick={() => {setunblock(true);handleSlotClick(slot.slotName, slot.slotStart, slot.slotEnd)}} >
                                                        <div className="flex justify-center max-md:text-[14px] text-[18px] px-[20px] py-[16px] rounded">
                                                            {formatTime(slot.slotStart)} - {formatTime(slot.slotEnd)}
                                                        </div>
                                                    </div> :
                                                        <div key={`${index}-${slotIndex}`} style={{ cursor: "pointer" }} className={`flex justify-center text background whitespace-nowrap rounded-[4px] ${selectedSlottId === `${slot.slotStart}-${slot.slotEnd}` ? 'border' : 'borderBefore'}`} onClick={() => {setunblock(false); handleSlotClick(slot.slotName, slot.slotStart, slot.slotEnd)}}>
                                                            <div className="flex justify-center max-md:text-[14px] px-[20px] py-[16px] rounded">
                                                                {formatTime(slot.slotStart)} - {formatTime(slot.slotEnd)}
                                                            </div>
                                                        </div>
                                                ))
                                        ) : null
                                    ))}
                                </div>

                            }
                        </div>
                    </div>
                    <div className=' '>
                        {/* Counter */}

                        <div className='w-full gap-5' >


                            <div>
                                <div className="heading mt-10  max-md:px-5">
                                    Select Duration
                                </div>
                                <div className="flex gap-5 justify-center  max-md:px-5 mt-10 max-md:mt-10">
                                    <button onClick={decreaseCounter} className="flex justify-center items-center px-2 w-10 h-10 background rounded-[100px]">
                                        <RiSubtractFill size={25} className='icon' />
                                    </button>
                                    <div className="my-auto text-[20px] font-bold leading-5 text-black">
                                        {counter} Hour(s)
                                    </div>
                                    <button onClick={increaseCounter} className="flex justify-center items-center px-2 w-10 h-10 background rounded-[100px]">
                                        <IoMdAdd size={25} className='icon' />
                                    </button>
                                </div>
                                {/* </div>

<div> */}
                                <div className="  heading lg:mt-7 mt-10  max-md:px-5">
                                    Select Turf
                                </div>
                                <div className="max-md:px-5 mt-10 lg:mt-[24px]">
                                    {sportsData.map((item, index) => (
                                        item?.sportId === selectedSportId && item.slotData ? (
                                            item.slotData.map((slot, slotIndex) => {
                                                // Set a value here
                                                // const availableSlot = calculateAvailableSlot(slot);
                                            
                                                // Perform action for each element in the array
                                                if(selectedSportId==="Z9rTeMFLF7xLLXKBANqz88") {
                                                    
                                                    return <div key={`${index}-${slotIndex}`} className="grid grid-cols-2 gap-5">
                                                                {selectedSlottId === `${slot.slotStart}-${slot.slotEnd}` && slot.Turfs && footballturf.map((turf, turfIndex) => (
                                                                    slot.Turfs.find(fturf => fturf.name === turf.name)? <div key={`${index}-${slotIndex}-${turfIndex}`} className={`flex justify-center text background rounded whitespace-nowrap ${turfName === turf.name ? 'border' : 'borderBefore'}`} onClick={() => {setunblock(false);handleTurfClick(turf.price, turf.name)}} style={{ cursor: "pointer" }}>
                                                                    <div className="flex justify-center max-md:text-[14px]  px-[20px] py-[16px] rounded">
                                                                        {turf.name}
                                                                    </div>
                                                                </div>:<div key={`${index}-${slotIndex}-${turfIndex}`} className={`flex justify-center text background rounded whitespace-nowrap ${turfName === turf.name ? 'border' : 'borderBefore'}`}  style={{ cursor: "pointer",opacity: "0.5", backgroundColor: "red", color: "white" }} onClick={() => {setunblock(true);handleTurfClick(turf.price, turf.name)}}>
                                                                    <div className="flex justify-center max-md:text-[14px]  px-[20px] py-[16px] rounded">
                                                                        {turf.name}
                                                                    </div>
                                                                </div>
                                                                    
                                                                   
                                                                ))}
                                                            </div>
                                                } else {
                                                    
                                                    return <div key={`${index}-${slotIndex}`} className="grid grid-cols-2 gap-5">
                                                                {selectedSlottId === `${slot.slotStart}-${slot.slotEnd}` && slot.Turfs && cricketturf.map((turf, turfIndex) => (
                                                                    turf.name === "7 v 7 Turf C" && slot.Turfs.find(fturf => fturf.name === turf.name) ? (
                                                                        <div key={`${index}-${slotIndex}-${turfIndex}`} className={`flex justify-center text background rounded whitespace-nowrap ${turfName === turf.name ? 'border' : 'borderBefore'}`} onClick={() => {setunblock(false);handleTurfClick(turf.price, turf.name)}} style={{ cursor: "pointer" }}>
                                                                            <div className="flex justify-center max-md:text-[14px]  px-[20px] py-[16px] rounded">
                                                                                {turf.name}
                                                                            </div>
                                                                        </div>
                                                                    ) : <div key={`${index}-${slotIndex}-${turfIndex}`} className={`flex justify-center text background rounded whitespace-nowrap ${turfName === turf.name ? 'border' : 'borderBefore'}`}  style={{ cursor: "pointer",opacity: "0.5", backgroundColor: "red", color: "white" }} onClick={() => {setunblock(true);handleTurfClick(turf.price, turf.name)}}>
                                                                    <div className="flex justify-center max-md:text-[14px]  px-[20px] py-[16px] rounded">
                                                                        {turf.name}
                                                                    </div>
                                                                </div>
                                                                ))}
                                                            </div>
                                                }
                                            })
                                        ) : null
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Turf */}
                    </div>
                </div>
                {/* Slot */}


                <div className='max-w-[1024px] lg:w-[1024px] max-md:w-full max-md:px-5  lg:mx-auto'>
 
                  <div className="flex justify-center items-center mt-10">
                        {selectedSportId!=null && selectedSlotName!=="" && turfName!==null && unblock==false?<button className="flex justify-center items-center !font-semibold !text-[17px] lg:max-w-[400px] w-full py-4 btn rounded-[44px]" onClick={() => {BlockSlotForDate()}}>
                            Block
                        </button>:""}
                    </div>
                </div>
                <div className="flex justify-center items-center mt-10">
                        {selectedSportId!=null && selectedSlotName!=="" && turfName!==null && unblock==true?<button className="flex justify-center items-center !font-semibold !text-[17px] lg:max-w-[400px] w-full py-4 btn rounded-[44px]" onClick={() => {UnBlockSlotForDate()}}>
                            Unblock
                        </button>:""}
                </div>
                

            </div> 



        </div>

    )


}

export default SlotsMgmt



