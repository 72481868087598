import React, { useContext, useState } from 'react';
import { AuthContext } from '../Context/AuthProvider';

const BookingFilter = (props) => {
    const { sendFilterBookingIdRequest, sendFilterBookingPaymentStatusRequest, sendFilterDatesRequest, EngineUrl, setBookingData,
        fetchLoneStarThisMonthBookings, getAllBookingsData, fetchDateRangeBookings, fetchLoneStarBookingIdBookings,
        fetchLoneStarBookingPaymentstatusBookings,date,websiteData,Url} = useContext(AuthContext)


    const { mheading, setAllBookings } = props;

    const [selectedValue, setSelectedValue] = useState('div0');
    const [selectedNumber, setselectedNumber] = useState('1')
    const [bookingId, setBookingId] = useState('');
    const [checkinDate, setCheckinDate] = useState('')
    const [checkoutDate, setCheckoutDate] = useState('')









    const handleOptionChange = (event) => {
        setSelectedValue(event.target.value);

    };

    const MarkIndividualHoliday = async()=>{
        
        try {
            const response = await fetch(
                `${Url}/sports/holidayhandle/${localStorage.getItem("adminToken")}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json, text/plain, /",
                        "Content-Type": "application/json",
                    },
                body: JSON.stringify({
                    [date]:true
                }),
                }
            );
    
            const json = await response.json();
            console.log(json);
            if (json.Status) {
                websiteData()
            } else {
            }
            } catch {
            // alert("Some Problem update token");
            }
    }

    const UnMarkIndividualHoliday = async()=>{
        try {
            const response = await fetch(
                `${Url}/sports/holidayhandle/${localStorage.getItem("adminToken")}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json, text/plain, /",
                        "Content-Type": "application/json",
                    },
                body: JSON.stringify({
                    [date]:false
                }),
                }
            );
    
            const json = await response.json();
            console.log(json);
            if (json.Status) {
                websiteData()
            } else {
            }
            } catch {
            // alert("Some Problem update token");
            }
    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    

    const MarkDateRangeHoliday = async()=>{
        if (checkinDate==="" ||  checkoutDate==""){
            alert("Please fill dates ")
        }
        else{
            const datesInRange = [];
            const currentDate = new Date(checkinDate);
    
            while (currentDate <= new Date(checkoutDate)) {
                datesInRange.push(formatDate(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }
            let data={}
            for(let i=0;i<datesInRange.length;i++){
                data[datesInRange[i]] = true
            }

       
            try {
                const response = await fetch(
                    `${Url}/sports/holidayhandle/${localStorage.getItem("adminToken")}`,
                    {
                        method: "POST",
                        headers: {
                            Accept: "application/json, text/plain, /",
                            "Content-Type": "application/json",
                        },
                    body: JSON.stringify(data),
                    }
            );

            const json = await response.json();
            if (json.Status) {
                websiteData()
                setCheckinDate("")
                setCheckoutDate("")
            } else {
            }
            } catch {
            // alert("Some Problem update token");
            }
        }
    }

    return (
        <div className='reservationdesktable max-md:px-5'>
            <h3>{mheading}</h3>
            <div className="b-filters">
                {/* <h5>Filters</h5> */}
                {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3 flex flex-wrap gap-10 max-md:gap-5">
                        <label htmlFor='' className='flex items-center gap-1'>
                            <input
                                type={type}
                                checked={selectedValue === 'div0'}
                                value="div0"
                                onClick={(e) => { handleOptionChange(e); getAllBookingsData() }}
                                id={`inline-${type}-2`}
                            />
                            Manual

                        </label>
                        <label htmlFor='' className='flex items-center gap-1'>
                            <input
                                type={type}
                                checked={selectedValue === 'div1'}
                                value="div1"
                                onClick={(e) => { handleOptionChange(e); fetchLoneStarThisMonthBookings() }}
                                id={`inline-${type}-2`}
                            />
                            Date Range Holiday Mark

                        </label>
                        
                        <button className='self-center btn  py-[.3rem] px-[1rem]' onClick={() => { setSelectedValue("") }}>close</button>
                    </div>
                ))}
            </div>
            <div className="filter">
                <div id="div0" style={{ display: selectedValue === 'div0' ? 'block' : 'none' }}>
                    <button style={{"float":"right","border":"1px solid green"}} className='flex justify-center items-center !font-semibold !text-[17px] lg:max-w-[200px] sm:max-w-[100px] w-full py-4 btn rounded-[44px]' onClick={()=>{UnMarkIndividualHoliday()}}>Un-mark Holiday</button>
                    <button style={{"float":"right","border":"1px solid green"}} className='flex justify-center items-center !font-semibold !text-[17px] lg:max-w-[200px] sm:max-w-[100px] w-full py-4 btn rounded-[44px]' onClick={()=>{MarkIndividualHoliday()}}>Mark Holiday</button>
                </div>
               
                <div id="div1" style={{ display: selectedValue === 'div1' ? 'block' : 'none' }}>

                    <div className="date-fltr ">
                        <div className="dt-flt-inr grid grid-cols-3 max-md:grid-cols-1 max-md:gap-4 mt-5">
                            <div className="flex gap-5 max-md:gap-1 items-center max-md:justify-between flex-wrap">
                                <label htmlFor="/">Reservations From:</label>
                                <input className='dates border-2 rounded-sm  py-[.3rem] px-[1rem]' type="date" name="" id="checkin" value={checkinDate} onChange={(e) => { setCheckinDate(e.target.value) }} />
                            </div>
                            <div className="flex gap-5 items-center max-md:justify-between max-md:gap-1 flex-wrap">
                                <label htmlFor="/">Reservations To:</label>
                                <input className='dates border-2 rounded-sm  py-[.3rem] px-[1rem]' type="date" name="" id="checkout" value={checkoutDate} onChange={(e) => { setCheckoutDate(e.target.value) }} />
                            </div>
                            <div className='flex items-center'>
                                <button className="btn py-[.3rem] px-[1rem] w-[30%] max-md:w-[auto]" onClick={() => {MarkDateRangeHoliday() }}>Mark Holidays</button>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default BookingFilter